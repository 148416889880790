import React, { Component } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { SERVICE_URL, SERVICE_IMG } from '../constants/global';

export class HomeContenido extends Component {
    static displayName = HomeContenido.name;

    constructor(props) {
        super(props);

        this.state = {
            items: [],
        };
    }

    componentDidMount() {
        this.getData();
    }

    getData() {
        var Query = "/api/HomeContenido";
        var urlServicio = SERVICE_URL + Query;
        axios.get(urlServicio).then(res => {
            var data = res.data;
            //console.log(data);
            this.setState({ items: data })
        })
    }

    render() {
        return (
            <div>
                <div className="container py-5">
                    <div className="row g-4">
                        {this.state.items.map(function (d) {
                            return (
                                <div key={d.id} className={"col-lg-"+d.posicion +" col-sm-12 mb-5"}>
                                    <div>
                                        <Link to={{ pathname: '/' + d.url + '/' }} > <div className="img__header__content" style={{ backgroundImage: 'url(' + SERVICE_IMG + d.rutaArchivo + ')' }}></div></Link>                                       
                                        <div className="mt-4">
                                            <Link to={{ pathname: '/' + d.url +'/' }} ><h5 className="card-title history__card__title">{d.titulo}</h5></Link>                                                                                      
                                            <p className="card-text history__card__description">{d.descripcionBreve}</p>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        );
    }
}