import React, { Component } from 'react';
import { Banner } from './../components/Banner';
import { HistoriaHome } from './../components/HistoriaHome';
import { HomeContenido } from './../components/HomeContenido';
import { Alerta } from './../components/Alerta';
import './Home.css';

export class Home extends Component {
    static displayName = Home.name;


  render () {
    return (
     <div>
            <Alerta />
            <Banner />
            <HomeContenido />
            <HistoriaHome />
      </div>
    );
  }
}
