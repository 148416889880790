import React, { Component, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import axios from 'axios';
import { SERVICE_URL, SERVICE_IMG } from '../constants/global';
import { ConsultaProductoRuta1 } from './../components/ConsultaProductoRuta1';

export const ConsultaTipoNegocio = () => {
    const [vlListValores, setListValores] = useState([]);
    const [vlVerProducto, setVerProducto] = useState(0);

    var Query = "/api/ContactoTipoNegocio";
    var urlServicio = SERVICE_URL + Query;

    useEffect(() => {
        axios
            .get(urlServicio)
            .then((response) => {
                if (response.status === 200) {
                    setListValores(response?.data);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    });

    const handleChange = event => {
        var value = event.target.value;
        var index = event.nativeEvent.target.selectedIndex;
        setVerProducto(parseInt(value));        
    };

    return (
        <div>
            <div className="form-group">
                <Form.Select defaultValue={""} onChange={handleChange} className="form-control consulta__input" placeholder="Selecciona una opción" id="contact__tipo__negocio" required>
                    <option value=""> Seleccione tipo negocio</option>
                    {vlListValores.map(function (item, key) {
                        return (
                            <option key={key} value={item.idLineaNegocio}>{item.titulo}</option>
                        );
                    })}
                </Form.Select>
            </div>
            { vlVerProducto!=0 ? <ConsultaProductoRuta1 peIdTipoNegocio={vlVerProducto} /> : ''}
        </div>            
    );
}