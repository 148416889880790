/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-target-blank */
import React, { Component } from 'react';
import {
    Nav, Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';
import { Menu } from './../components/Menu';
import * as $ from 'jquery';

export class NavMenu extends Component {
    static displayName = NavMenu.name;

    constructor(props) {
        super(props);

        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.state = {
            collapsed: true
        };
    }

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }

    search = () => {
        var word = $("#input__search").val();
        var buscador = '/Buscador?word=' + word;
        window.location.href = buscador;
    }

    handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            var word = $("#input__search").val();
            var buscador = '/Buscador?word=' + word;
            window.location.href = buscador;
        }
    }

    render() {
        return (
            <header>
                <Navbar className="border-red navbar navbar-light mb-2" light>                    
                    <Container>
                        <div className="header-title">
                            <NavbarBrand tag={Link} to="/"><img alt="logo_aj" src="../images/logo_aj.svg" /></NavbarBrand>
                            <a target="_blank" href="https://www.ajinomoto.com/" rel="noopener" className="header-title__label has-langs">Sitio web global del Grupo Ajinomoto</a>
                        </div>
                        <div className="header-utilities">
                            <nav className="header-utility">
                                <a className="header-utility__item" href="#" hidden>
                                    <img className="header-utility__item__ico" src="../images/ico_externalinks.svg" width="16" height="16" alt="External website links" />
                                    <span className="header-utility__item__text">External website links</span>
                                </a>
                            </nav>
                            <div className="header-search-wide mt-3">
                                <input type="text" onKeyPress={this.handleKeyPress}  title="Escriba la palabra de busqueda aqui" name="input__search" id="input__search" tabindex="0" placeholder="Buscar" className="header-search-wide__input" />
                                <button type="button" onClick={this.search} tabindex="-1" className="header-search-wide__submit"><img src="../images/ico_search.svg" width="16" height="16" alt="Buscar" /></button>
                            </div>
                        </div>
                        
                    </Container>
                </Navbar>
                <Navbar className="navbar-expand-sm navbar-toggleable-sm bgMenu" light>
                     <div className="container">
                        <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
                        <Collapse className="d-sm-inline-flex" isOpen={!this.state.collapsed} navbar>
                            <Menu />
                        </Collapse>
                        <div>
                            <ul className="navbar-nav flex-grow">
                                <NavItem>
                                    <NavLink tag={Link} className="text-dark text__submenu" to="/oportunidad-laboral">Oportunidades Laborales</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink tag={Link} className="text-dark text__submenu" to="/consultas">Contacto</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink tag={Link} className="text-dark text__submenu" to="/donde-estamos">Donde estamos</NavLink>
                                </NavItem>
                            </ul>
                        </div>
                    </div>
                </Navbar>                
            </header>
        );
    }
}
