import React, { Component } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import ReactPlayer from 'react-player'
import { Link } from 'react-router-dom';
import axios from 'axios';
import { SERVICE_URL, SERVICE_IMG } from '../constants/global';

export class Banner extends Component {
    static displayName = Banner.name;

    constructor(props) {
        super(props);

        this.state = {
            items: [],
        };
    }

    componentDidMount() {
        this.getData();
    }

    getData() {
        var Query = "/api/Banner";
        var urlServicio = SERVICE_URL + Query;
        axios.get(urlServicio).then(res => {
            var data = res.data;
            //console.log(data);
            this.setState({ items: data })
        })
    }

    render() {
        let screenWidth = window.innerWidth;

        if (screenWidth > 768) {
            return (
                <div>
                    <Carousel showStatus={false} showThumbs={false} autoPlay={true} interval={15000} infiniteLoop>
                        {this.state.items.map(function (item, key) {
                            //Enlace
                            let Enlace = "#";
                            debugger;
                            if (item.url != null && item.url !== undefined) {
                                Enlace = item.url;
                            }

                            if (item.urlVideo != undefined && item.urlVideo != null && item.urlVideo != "") {
                                return (
                                    <div key={key}>
                                        <ReactPlayer
                                            url={item.urlVideo}
                                            volume='1'
                                            className="d-block img-fluid"
                                            muted
                                            width='100%'
                                            playing={true}
                                        />
                                        <div className="carousel-caption">
                                            <h1 className="display-2">{item.titulo}</h1>
                                            <h3>{item.descripcion}</h3>
                                        </div>
                                    </div>
                                );
                            }
                            else {
                                if (item.titulo == "Sin Titulo") {
                                    return (
                                        <div key={key}>
                                            <a href={Enlace} target="_blank">
                                                <img className="d-block img-fluid" src={SERVICE_IMG + item.rutaArchivo} />
                                            </a>
                                        </div>
                                    );
                                }
                                else {
                                    return (
                                        <div key={key}>
                                            <a href={Enlace} target="_blank">
                                                <img className="d-block img-fluid" src={SERVICE_IMG + item.rutaArchivo} />
                                                <div className="carousel-caption">
                                                    <h1 className="display-2">{item.titulo}</h1>
                                                    <h3>{item.descripcion}</h3>
                                                </div>
                                            </a>
                                        </div>
                                    );
                                }
                            }

                        })}
                    </Carousel>
                </div>
            );
        }
        else {
            return (
                <div>
                    <Carousel showStatus={false} showThumbs={false} autoPlay={true} interval={15000} infiniteLoop>
                        {this.state.items.map(function (item, key) {
                            //Enlace
                            let Enlace = "#";
                            debugger;
                            if (item.url != null && item.url !== undefined) {
                                Enlace = item.url;
                            }

                            return (
                                <div key={key}>
                                    <a href={Enlace} target="_blank">
                                        <img className="d-block img-fluid" src={SERVICE_IMG + item.rutaMiniatura} />
                                    </a>
                                </div>
                            );

                        })}
                    </Carousel>
                </div>
            );
        }

       
    }
}