import React from 'react';
import Receta from '../components/Receta';

const RecetasList = ({ recetas }) => {
    return (
        <div className="row row-cols-1 row-cols-md-3 g-4">
            {recetas && recetas.map((receta) => <Receta key={receta.urlSlug} {...receta} />)}
        </div>
    );
};

export default RecetasList;
