import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Carousel } from 'react-responsive-carousel';
import { SERVICE_URL, SERVICE_IMG } from '../constants/global';

export class Alerta extends Component {
    static displayName = Alerta.name;

    constructor(props) {
        super(props);

        this.state = {
            items: [],
        };
    }

    componentDidMount() {
        this.getData();
    }

    getData() {
        var Query = "/api/HomeAlerta";
        var urlServicio = SERVICE_URL + Query;
        axios.get(urlServicio).then(res => {
            var data = res.data;
            //console.log(data);
            this.setState({ items: data })
        })
    }

    render() {
        return (
            <div className="alerta__home">
                <Carousel showStatus={false} showThumbs={false} autoPlay={true} interval={15000} infiniteLoop>
                    {this.state.items.map(function (item, key) {

                        return (
                            <div key={key} className="row mt-4 mb-2">
                                <div className="col-lg-1 mt-3 text-right">
                                    <img className="alerta__icon" src={SERVICE_IMG + item.rutaArchivo} />
                                </div>
                                <div className="col-lg-11 text-left">
                                    <p className="alert__title">{item.titulo}</p>
                                    <p className="alert__description">{item.descripcion}</p>
                                </div>
                            </div>
                        );

                    })}
                </Carousel>
            </div>
        );
    }
}