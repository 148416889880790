import React, { Component } from 'react';
import axios from 'axios';
import { SERVICE_URL, SERVICE_IMG } from '../constants/global';
import ReactHtmlParser from 'react-html-parser';
import moment from 'moment';
import { IProductoItem } from '../models/IProductoItem';
import { Tienda } from '../components/Tienda';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
moment.locale("es");

export class Producto extends Component<any, IProductoItem> {
    private params: any;
    static displayName = Producto.name;

    constructor(props: any) {
        super(props);

        this.state = {
            id: 0,
            titulo: "",
            resumen: "",
            descripcion: "",
            seccion1: "",
            seccion2: "",
            rutaArchivo: "",
            tipeMIME: "",
            rutaEmpaque: "",
            tipeMIMEEmpaque: "",
            rutaMiniatura: "",
            tipeMIMEMiniatura: "",
            idLineaNegocio: 0,
            categoria: {
                id: 0,
                titulo: "",
                activo: true,
                descripcion: "",
            },
            idCategoria: 0,
            nombreCategoria: "",
            activo: true,
            tiendas: [],
            urlSlug: "",
        }
    }

    componentDidMount() {
        this.getData(this.props.match.params.url);
    }

    getData(url:string) {
        var Query = "/api/Producto/ByUrlAndDetails/" + url;
        var urlServicio = SERVICE_URL + Query;
        axios.get(urlServicio).then(res => {
            var data = res.data;
            console.log(data);
            this.setState({
                id: data.id,
                titulo: data.titulo,
                resumen: data.resumen,
                descripcion: data.descripcion,
                seccion1: data.seccion1,
                seccion2: data.seccion2,
                rutaArchivo: data.rutaArchivo,
                rutaMiniatura: data.rutaMiniatura,
                activo: data.activo,
                tiendas: data.tiendas,
                urlSlug: data.urlSlug,
            })
        })
    }

    render() {
        var urlImagen = "";
        if (this.state.rutaArchivo != null && this.state.rutaArchivo != undefined && this.state.rutaArchivo != "") {
            urlImagen = this.state.rutaArchivo;
        }
        else {
            urlImagen = this.state.rutaMiniatura;
        }
        
        let verCarrito = {};
        if (this.state.tiendas.length > 0) {
            verCarrito = { display: "block" };
        }
        else {
            verCarrito = { display: "none" };
        }
        return (
            <div>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>{this.state.titulo} | Ajinomoto del Perú</title>
                    <meta property="og:title" content={this.state.titulo + ' | Ajinomoto del Perú'} />
                    <meta property="og:image" content={SERVICE_IMG + urlImagen} />
                </Helmet>
                <div className="container container__pages py-5">
                    <h5 className="card-title title__receta mb-4">{this.state.titulo}</h5>
                    <img src={SERVICE_IMG + this.state.rutaArchivo} className="img-fluid" alt="Header" />
                    <div className="mb-4 mt-4">
                        <div>
                            {ReactHtmlParser(this.state.descripcion)}
                        </div>
                    </div>
                    <div className="row mb-4 mt-4">
                        <div className="col-lg-6">
                            {ReactHtmlParser(this.state.seccion1)}
                        </div>
                        <div className="col-lg-6">
                            {ReactHtmlParser(this.state.seccion2)}
                        </div>
                    </div>

                    <div className="mb-5 mt-5" style={verCarrito}>                        
                        <div className="col-lg-4 carrito__container__producto">                            
                            <img src="../images/ico_carrito.svg" className="carrito__img" />&nbsp;&nbsp;COMPRAR AHORA
                        </div>
                    </div>
                    <div className="text-center mb-5">
                        <Link to={'/lineas-negocio'} className="btn history__buton__vermas">Regresar</Link>
                    </div>
                </div>
            </div>
        );
    }

    
}
