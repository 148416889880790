import React, { Component } from 'react';
import ReactHtmlParser from 'react-html-parser';

export class SiteMap extends Component {
    static displayName = SiteMap.name;

    constructor(props) {
        super(props);
    }

    render() {
        var htmlBody = '';
        return (
            <div className="container container__pages py-5">
                <h2 className='text-center mb-4'>Mapa del Sitio</h2>
                <div>
                    {ReactHtmlParser(htmlBody)}
                </div>
            </div>
        );
    }
}