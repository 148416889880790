import React, { Component } from 'react';
import { Form } from "react-bootstrap";
import axios from 'axios';
import { SERVICE_URL, SERVICE_IMG } from '../constants/global';

export class ConsultasTipo extends Component {
    static displayName = ConsultasTipo.name;

    constructor(props) {
        super(props);

        this.state = {
            items: [],
        };
    }

    componentDidMount() {
        this.getData();
    }    

    getData() {
        var Query = "/api/ContactoTipo";
        var urlServicio = SERVICE_URL + Query;
        axios.get(urlServicio).then(res => {
            var data = res.data;
            //console.log(data);
            this.setState({ items: data })
        })
    }

    render() {
        return (
            <div className="form-group">
                <Form.Select defaultValue={""} className="form-control consulta__input" placeholder="Selecciona una opción" id="contact__tipo__contacto" required>
                    <option value=""> Selecciona usuario</option>
                    {this.state.items.map(function (item, key) {
                        return (
                            <option key={key} value={item.id}>{item.titulo}</option>
                        );
                    })}
                </Form.Select>
            </div>
        );
    }
}