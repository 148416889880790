import React, { Component, useEffect, useState } from "react";
import MapContainer from './../components/MapContainer';
import { SERVICE_URL, SERVICE_IMG } from '../constants/global';
import axios from 'axios';
import * as $ from 'jquery';
import { Button, Card, Modal, Form } from "react-bootstrap";
import swal from 'sweetalert';
import CircularProgress from '@material-ui/core/CircularProgress';

import { ConsultasMotivo } from './../components/ConsultasMotivo';
import { ConsultasTipo } from './../components/ConsultasTipo';

import './SubSection.css';

export const Consultas = () => {
    const [validated, setValidated] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [error, seterror] = useState(false);

    const validateForm = (formulario) => {

        let isInvalid = false;
        debugger
        Object.keys(error).forEach(x => {
            const errObj = error[x];
            if (errObj.show) {
                isInvalid = true;
            } else if (!formulario[x] && formulario[x] !== undefined) {
                isInvalid = true;
            }
        });
        return !isInvalid;
    }

    const handleSubmit = (event) => {
        debugger;

        let formulario = event.currentTarget;
        event.preventDefault();
        let isValid = validateForm(formulario);
        if (formulario.checkValidity() === false || !isValid) {
            event.stopPropagation();
            setValidated(true);
        }
        else {
            setValidated(false);

            swal({
                title: "Desea enviar la consulta?",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
            .then((userConfirmation) => {
                if (userConfirmation) {
                    setLoading(true);
                    var Query = "/api/Contacto";
                    var urlServicio = SERVICE_URL + Query;

                    var contact__asunto = $('#contact__asunto option:selected').text();
                    var contact__tipo__contacto = $('#contact__tipo__contacto option:selected').text();
                    var contact__tipo__negocio = $('#contact__tipo__negocio option:selected').text();
                    var contact__producto__ruta1 = $('#contact__producto__ruta1 option:selected').text();
                    var contact__producto__ruta2 = $('#contact__producto__ruta2 option:selected').text();
                    var contact__nombres = $("#contact__nombres").val();
                    var contact__telefono = $("#contact__telefono").val();
                    var contact__email = $("#contact__email").val();
                    var contact__edad = $("#contact__edad").val();
                    var contact__ocupacion = $("#contact__ocupacion").val();
                    var contact__Pais = $("#contact__Pais").val();
                    var contact__Ciudad = $("#contact__Ciudad").val();
                    var contact__direccion = $("#contact__direccion").val();
                    var contact__Mensaje = $("#contact__Mensaje").val();

                    var TipoNegocio = "";
                    if (contact__tipo__negocio != "" && contact__tipo__negocio != null) {
                        TipoNegocio = contact__tipo__negocio;
                    }

                    var Producto = "";
                    if (contact__producto__ruta1 != "" && contact__producto__ruta1 != null) {
                        Producto = contact__producto__ruta1;
                    }
                    else if (contact__producto__ruta2 != "" && contact__producto__ruta2 != null) {
                        Producto = contact__producto__ruta2;
                    }

                    var Ocupacion = "";
                    if (contact__ocupacion != "" && contact__ocupacion != null) {
                        Ocupacion = contact__ocupacion;
                    }

                    const consulta = {
                        id: 0,
                        asunto: contact__asunto,
                        tipoContacto: contact__tipo__contacto,
                        tipoNegocio: TipoNegocio,
                        producto: Producto,
                        nombreCompleto: contact__nombres,
                        telfCel: contact__telefono,
                        edad: contact__edad,
                        email: contact__email,
                        ocupacion: Ocupacion,
                        pais: contact__Pais,
                        ciudad: contact__Ciudad,
                        direccion: contact__direccion,
                        mensaje: contact__Mensaje,
                        fecha: new Date()
                    };

                    const headers = {
                        'content-type': 'application/json',
                        "Access-Control-Allow-Methods": "OPTIONS,POST,GET"
                    };

                    axios.post(urlServicio, JSON.stringify(consulta), { headers })
                        .then(response => {
                            setLoading(false);
                            if (response.status === 200) {
                                swal("Portal web AJINOMOTO","su consulta ha sido enviada, por favor espere una respuesta por parte de nuestro equipo.", "success",)
                                .then((value) => {
                                    window.location.href = "/";
                                });
                            }
                        })
                        .catch(error => {
                            setLoading(false);
                            console.log('There was an error!', error);
                            swal("Error al consultar servicio", error.response.data, "error", {
                                button: "OK",
                            });
                        });
                }
            });
        }
    }

    return (
        <div>
            <header className="masthead">
                <img src="../images/cabecera-contacto.jpg" className="img-fluid" alt="Header" />
                <div className="carousel-caption">
                    <h1 className="display-2">Contacto</h1>
                    <h3>Para consultas sobre el Grupo Ajinomoto, las empresas de nuestro grupo, actividades corporativas, cobertura de prensa, o para contactarnos directamente.</h3>
                </div>
            </header>
            <div className="container py-5">
                <Form noValidate validated={validated} id="contact__form" className="row row-cols-1 row-cols-md-3 g-4" onSubmit={handleSubmit}>
                    <div className="col-lg-6">
                        <p className="title__section">ENV&#205;E UN MENSAJE</p>
                        <ConsultasTipo />
                        <ConsultasMotivo />
                        <div className="form-group">
                            <Form.Control className="form-control consulta__input" type="input" id="contact__nombres" placeholder="Nombres" required />
                            <Form.Control.Feedback type="invalid">
                                Ingresa Nombres
                            </Form.Control.Feedback>
                        </div>
                        <div className="form-row">
                            <div className="col mb-2">
                                <Form.Control minLength={9} className="form-control consulta__input" type="input" id="contact__telefono" placeholder="Telf/Cel" required />
                                <Form.Control.Feedback type="invalid">
                                    Ingresa Telf/Cel
                                </Form.Control.Feedback>
                            </div>
                            <div className="col mb-2">
                                <Form.Control className="form-control consulta__input" type="email" id="contact__email" placeholder="E-mail" required />
                                <Form.Control.Feedback type="invalid">
                                    Ingresa E-mail
                                </Form.Control.Feedback>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="col mb-2">
                                <Form.Control className="form-control consulta__input" type="input" id="contact__edad" placeholder="Edad" required />
                                <Form.Control.Feedback type="invalid">
                                    Ingresa Edad
                                </Form.Control.Feedback>
                            </div>
                            <div className="col mb-2">
                                <Form.Control className="form-control consulta__input" type="input" id="contact__ocupacion" placeholder="Ocupaci&#243;n" />
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="col mb-2">
                                <Form.Control className="form-control consulta__input" type="input" id="contact__Pais" placeholder="Pa&#237;s" required />
                                <Form.Control.Feedback type="invalid">
                                    Ingresa Pa&#237;s
                                </Form.Control.Feedback>
                            </div>
                            <div className="col mb-2">
                                <Form.Control className="form-control consulta__input" type="input" id="contact__Ciudad" placeholder="Ciudad" required />
                                <Form.Control.Feedback type="invalid">
                                    Ingresa Ciudad
                                </Form.Control.Feedback>
                            </div>
                        </div>
                        <div className="form-group">
                            <Form.Control className="form-control consulta__input" type="input" id="contact__direccion" placeholder="Direcci&#243;n" required />
                            <Form.Control.Feedback type="invalid">
                                Ingresa Direcci&#243;n
                            </Form.Control.Feedback>
                        </div>
                        <div className="form-group">
                            <Form.Control className="form-control consulta__input" rows={6} as="textarea" id="contact__Mensaje" placeholder="Mensaje" required />
                            <Form.Control.Feedback type="invalid">
                                Ingresa Mensaje
                            </Form.Control.Feedback>
                        </div>
                        <div className="form-group text-center">
                            {isLoading && <CircularProgress color="dark" />}
                            {!isLoading &&
                                <>
                                <button type="submit" className="form-control contacto__button">
                                    {isLoading ? 'CARGANDO...' : 'ENVIAR'}
                                </button>
                                </>
                            }                            
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <p className="title__section">&nbsp;</p>
                        <div className="row row-cols-1 row-cols-md-1 g-4">
                            <div className="col mb-2">
                                <MapContainer />
                            </div>
                            <div className="col mb-2">
                                <div className="section__contacto__bg">
                                    <p className="subtitle__section">ENV&#205;E UN MENSAJE</p>
                                    <div className="row row-cols-12 row-cols-md-12 g-4 mb-3">
                                        <div className="col mb-2">
                                            <span className="description__subtitle__section">Av. Rep&#250;blica de Panam&#225; 2455 Lima 13 - Per&#250;</span><br/>
                                            <span className="description__subtitle__section">Telf.: +511 470 6050</span>
                                        </div>
                                    </div>
                                    <p className="subtitle__section">PLANTA DE PRODUCCI&#211;N</p>
                                    <div className="row row-cols-12 row-cols-md-12 g-4">
                                        <div className="col mb-2">
                                            <span className="description__subtitle__section">Av.N&#233;stor Gambetta 7003 Callao - Per&#250;</span><br />
                                            <span className="description__subtitle__section">Telf.: +511 577 0033</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col mb-2">
                                <div className="section__contacto_call__bg">
                                    <span className="section__contacto_call__text">L&#205;NEA DE ATENCI&#211;N AL CLIENTE</span><br />
                                    <span className="section__contacto_call__text">0-800-24455</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </Form>
            </div>
        </div>
    )
}
