import React, { Component } from 'react';
import ReactHtmlParser from 'react-html-parser';

export class TermsUse extends Component {
    static displayName = TermsUse.name;

    constructor(props) {
        super(props);
    }

    render() {
		var htmlBody = `<p><span style="font-size:11pt"><span style="background-color:white"><span style="font-family:Calibri,sans-serif"><strong><span style="font-size:21.0pt"><span style="font-family:&quot;Calibri Light&quot;,sans-serif"><span style="color:#333333">1. Uso del sitio web.</span></span></span></strong></span></span></span></p>

<ol>
	<li><span style="font-size:11pt"><span style="background-color:white"><span style="font-family:Calibri,sans-serif"><span style="font-size:13.5pt"><span style="font-family:&quot;Calibri Light&quot;,sans-serif"><span style="color:#333333">Antes de usar este sitio web (</span></span></span><span style="color:black"><a href="http://www.ajinomoto.com.pe" style="color:blue; text-decoration:underline"><span style="font-size:13.5pt"><span style="font-family:&quot;Calibri Light&quot;,sans-serif">http://www.ajinomoto.com</span></span><span style="font-size:13.5pt"><span style="font-family:&quot;Calibri Light&quot;,sans-serif">.pe</span></span></a></span><span style="font-size:13.5pt"><span style="font-family:&quot;Calibri Light&quot;,sans-serif"><span style="color:#333333">; en lo sucesivo denominado colectivamente el &quot;Sitio web&quot;), lea detenidamente estos T&eacute;rminos de uso (en lo sucesivo, los &quot;T&eacute;rminos&quot;) y no podr&aacute; utilizar este Sitio web a menos que est&eacute; de acuerdo con los T&eacute;rminos.</span></span></span></span></span></span></li>
	<li><span style="font-size:11pt"><span style="background-color:white"><span style="font-family:Calibri,sans-serif"><span style="font-size:13.5pt"><span style="font-family:&quot;Calibri Light&quot;,sans-serif"><span style="color:#333333">Ajinomoto del Per&uacute; S.A. (en adelante, &quot;Ajinomoto&quot;) puede cambiar el contenido de los T&eacute;rminos sin previo aviso.</span></span></span></span></span></span></li>
</ol>

<p><span style="font-size:11pt"><span style="background-color:white"><span style="font-family:Calibri,sans-serif"><strong><span style="font-size:21.0pt"><span style="font-family:&quot;Calibri Light&quot;,sans-serif"><span style="color:#333333">2. Prohibici&oacute;n</span></span></span></strong></span></span></span></p>

<ol>
	<li><span style="font-size:11pt"><span style="background-color:white"><span style="font-family:Calibri,sans-serif"><span style="font-size:13.5pt"><span style="font-family:&quot;Calibri Light&quot;,sans-serif"><span style="color:#333333">Est&aacute; prohibido realizar los siguientes actos en relaci&oacute;n con el uso del sitio web.</span></span></span></span></span></span>

	<ol>
		<li><span style="font-size:11pt"><span style="background-color:white"><span style="font-family:Calibri,sans-serif"><span style="font-size:13.5pt"><span style="font-family:&quot;Calibri Light&quot;,sans-serif"><span style="color:#333333">Cualquier acto que viole o pueda violar la propiedad o privacidad de Ajinomoto o de un tercero.</span></span></span></span></span></span></li>
		<li><span style="font-size:11pt"><span style="background-color:white"><span style="font-family:Calibri,sans-serif"><span style="font-size:13.5pt"><span style="font-family:&quot;Calibri Light&quot;,sans-serif"><span style="color:#333333">Cualquier acto que perjudique o pueda perjudicar o causar da&ntilde;os a Ajinomoto o cualquier tercero.</span></span></span></span></span></span></li>
		<li><span style="font-size:11pt"><span style="background-color:white"><span style="font-family:Calibri,sans-serif"><span style="font-size:13.5pt"><span style="font-family:&quot;Calibri Light&quot;,sans-serif"><span style="color:#333333">Cualquier acto que sea o pueda ser ofensivo para las pol&iacute;ticas p&uacute;blicas.</span></span></span></span></span></span></li>
		<li><span style="font-size:11pt"><span style="background-color:white"><span style="font-family:Calibri,sans-serif"><span style="font-size:13.5pt"><span style="font-family:&quot;Calibri Light&quot;,sans-serif"><span style="color:#333333">Cualquier acto criminal o cualquier acto que conduzca o pueda conducir a un acto criminal.</span></span></span></span></span></span></li>
		<li><span style="font-size:11pt"><span style="background-color:white"><span style="font-family:Calibri,sans-serif"><span style="font-size:13.5pt"><span style="font-family:&quot;Calibri Light&quot;,sans-serif"><span style="color:#333333">Cualquier negocio o actividad comercial o cualquier acto realizado en preparaci&oacute;n para cualquier negocio o actividad comercial.</span></span></span></span></span></span></li>
		<li><span style="font-size:11pt"><span style="background-color:white"><span style="font-family:Calibri,sans-serif"><span style="font-size:13.5pt"><span style="font-family:&quot;Calibri Light&quot;,sans-serif"><span style="color:#333333">Hacer declaraciones o notificaciones falsas, incluido el registro de la direcci&oacute;n de correo electr&oacute;nico de otra persona como propia.</span></span></span></span></span></span></li>
		<li><span style="font-size:11pt"><span style="background-color:white"><span style="font-family:Calibri,sans-serif"><span style="font-size:13.5pt"><span style="font-family:&quot;Calibri Light&quot;,sans-serif"><span style="color:#333333">Usar o proporcionar virus inform&aacute;ticos u otros programas da&ntilde;inos.</span></span></span></span></span></span></li>
		<li><span style="font-size:11pt"><span style="background-color:white"><span style="font-family:Calibri,sans-serif"><span style="font-size:13.5pt"><span style="font-family:&quot;Calibri Light&quot;,sans-serif"><span style="color:#333333">Cualquier acto que viole o pueda violar cualquier ley, regulaci&oacute;n u ordenanza.</span></span></span></span></span></span></li>
		<li><span style="font-size:11pt"><span style="background-color:white"><span style="font-family:Calibri,sans-serif"><span style="font-size:13.5pt"><span style="font-family:&quot;Calibri Light&quot;,sans-serif"><span style="color:#333333">Cualquier otro acto que Ajinomoto considere inapropiado.</span></span></span></span></span></span></li>
	</ol>
	</li>
</ol>

<p style="margin-left:96px">&nbsp;</p>

<ol start="2">
	<li><span style="font-size:11pt"><span style="background-color:white"><span style="font-family:Calibri,sans-serif"><span style="font-size:13.5pt"><span style="font-family:&quot;Calibri Light&quot;,sans-serif"><span style="color:#333333">Si Ajinomoto descubre que ha cometido alguno de los actos prohibidos anteriores, puede eliminar la totalidad o parte de los datos en cuesti&oacute;n sin previo aviso. Sin embargo, Ajinomoto no tiene ninguna obligaci&oacute;n de monitorear constantemente el sitio web.</span></span></span></span></span></span></li>
</ol>

<p style="margin-left:48px">&nbsp;</p>

<p><span style="font-size:11pt"><span style="background-color:white"><span style="font-family:Calibri,sans-serif"><strong><span style="font-size:21.0pt"><span style="font-family:&quot;Calibri Light&quot;,sans-serif"><span style="color:#333333">3. Derechos de autor</span></span></span></strong></span></span></span></p>

<ol>
	<li><span style="font-size:11pt"><span style="background-color:white"><span style="font-family:Calibri,sans-serif"><span style="font-size:13.5pt"><span style="font-family:&quot;Calibri Light&quot;,sans-serif"><span style="color:#333333">Todos los derechos de autor y otros derechos sobre la informaci&oacute;n contenida en el sitio web pertenecen a Ajinomoto o los titulares de los derechos que le han otorgado a Ajinomoto la licencia para usar los derechos. Por lo tanto, m&aacute;s all&aacute; del alcance del uso privado de la informaci&oacute;n permitida por la ley, no puede participar en ning&uacute;n uso no autorizado de dicha informaci&oacute;n (incluida la realizaci&oacute;n de copias, falsificaci&oacute;n y distribuci&oacute;n de la informaci&oacute;n).</span></span></span></span></span></span></li>
	<li><span style="font-size:11pt"><span style="background-color:white"><span style="font-family:Calibri,sans-serif"><span style="font-size:13.5pt"><span style="font-family:&quot;Calibri Light&quot;,sans-serif"><span style="color:#333333">Todos los derechos de autor sobre el contenido de cualquier correo electr&oacute;nico enviado desde Ajinomoto a usted tambi&eacute;n pertenecen a Ajinomoto. Por lo tanto, no puede publicar o utilizar dichos contenidos para otros fines sin autorizaci&oacute;n.</span></span></span></span></span></span></li>
	<li><span style="font-size:11pt"><span style="background-color:white"><span style="font-family:Calibri,sans-serif"><span style="font-size:13.5pt"><span style="font-family:&quot;Calibri Light&quot;,sans-serif"><span style="color:#333333">Todas las marcas comerciales y marcas de servicio utilizadas en el sitio web pertenecen a Ajinomoto o los titulares de derechos que le han otorgado a Ajinomoto la licencia para usar las marcas. Por lo tanto, no puede participar en ning&uacute;n uso no autorizado de las marcas comerciales y marcas de servicio.</span></span></span></span></span></span></li>
</ol>

<p style="margin-left:48px">&nbsp;</p>

<p><span style="font-size:11pt"><span style="background-color:white"><span style="font-family:Calibri,sans-serif"><strong><span style="font-size:21.0pt"><span style="font-family:&quot;Calibri Light&quot;,sans-serif"><span style="color:#333333">4. Renuncias</span></span></span></strong></span></span></span></p>

<ol>
	<li><span style="font-size:11pt"><span style="background-color:white"><span style="font-family:Calibri,sans-serif"><span style="font-size:13.5pt"><span style="font-family:&quot;Calibri Light&quot;,sans-serif"><span style="color:#333333">Si bien Ajinomoto est&aacute; atento en la creaci&oacute;n y gesti&oacute;n de toda la informaci&oacute;n contenida en el sitio web, no ofrece ninguna garant&iacute;a sobre la exactitud e integridad de dicha informaci&oacute;n.</span></span></span></span></span></span></li>
	<li><span style="font-size:11pt"><span style="background-color:white"><span style="font-family:Calibri,sans-serif"><span style="font-size:13.5pt"><span style="font-family:&quot;Calibri Light&quot;,sans-serif"><span style="color:#333333">Sin previo aviso, Ajinomoto puede suspender o finalizar la administraci&oacute;n del sitio web y puede alterar, enmendar o cambiar toda o parte de la informaci&oacute;n contenida en el sitio web.</span></span></span></span></span></span></li>
	<li><span style="font-size:11pt"><span style="background-color:white"><span style="font-family:Calibri,sans-serif"><span style="font-size:13.5pt"><span style="font-family:&quot;Calibri Light&quot;,sans-serif"><span style="color:#333333">Ajinomoto no es responsable de ning&uacute;n da&ntilde;o que surja de usted como resultado de su uso del sitio web o de la inaccesibilidad del sitio web por cualquier motivo o por cualquier da&ntilde;o que surja en relaci&oacute;n con publicaciones, accesos no autorizados, comentarios o transmisi&oacute;n de correos electr&oacute;nicos por cualquier tercero (incluido cualquier da&ntilde;o en el que pueda incurrir por cualquier disputa que pueda tener con otro visitante).</span></span></span></span></span></span></li>
</ol>

<p style="margin-left:48px">&nbsp;</p>

<p><span style="font-size:11pt"><span style="background-color:white"><span style="font-family:Calibri,sans-serif"><strong><span style="font-size:21.0pt"><span style="font-family:&quot;Calibri Light&quot;,sans-serif"><span style="color:#333333">5. Manejo de informaci&oacute;n personal</span></span></span></strong></span></span></span></p>

<p><span style="font-size:11pt"><span style="background-color:white"><span style="font-family:Calibri,sans-serif"><span style="font-size:13.5pt"><span style="font-family:&quot;Calibri Light&quot;,sans-serif"><span style="color:#333333">Ajinomoto se compromete a utilizar su informaci&oacute;n personal de manera adecuada de acuerdo con su Pol&iacute;tica de privacidad.</span></span></span></span></span></span></p>

<ul>
	<li><span style="font-size:11pt"><span style="background-color:white"><span style="font-family:Calibri,sans-serif"><span style="color:black"><a href="https://www.ajinomoto.com.pe/privacy_policy" style="color:blue; text-decoration:underline"><span style="font-size:13.5pt"><span style="font-family:&quot;Calibri Light&quot;,sans-serif">https://www.ajinomoto.com.pe/politica_de_privacidad</span></span></a></span> </span></span></span></li>
</ul>

<p><span style="font-size:11pt"><span style="background-color:white"><span style="font-family:Calibri,sans-serif"><strong><span style="font-size:21.0pt"><span style="font-family:&quot;Calibri Light&quot;,sans-serif"><span style="color:#333333">6. Ambiente recomendado</span></span></span></strong></span></span></span></p>

<p><span style="font-size:11pt"><span style="background-color:white"><span style="font-family:Calibri,sans-serif"><span style="font-size:13.5pt"><span style="font-family:&quot;Calibri Light&quot;,sans-serif"><span style="color:#333333">Recomendamos el siguiente entorno al navegar por el sitio web. Es posible que el sitio web no se muestre o se comporte correctamente cuando se visualiza con un navegador que no sea el recomendado.</span></span></span></span></span></span></p>

<ul>
	<li><span style="font-size:11pt"><span style="background-color:white"><span style="font-family:Calibri,sans-serif"><span style="font-size:13.5pt"><span style="font-family:&quot;Calibri Light&quot;,sans-serif"><span style="color:#333333">Windows10 Chrome (&uacute;ltima versi&oacute;n)</span></span></span></span></span></span></li>
	<li><span style="font-size:11pt"><span style="background-color:white"><span style="font-family:Calibri,sans-serif"><span style="font-size:13.5pt"><span style="font-family:&quot;Calibri Light&quot;,sans-serif"><span style="color:#333333">Mac OS10.14 Chrome (&uacute;ltima versi&oacute;n)</span></span></span></span></span></span></li>
	<li><span style="font-size:11pt"><span style="background-color:white"><span style="font-family:Calibri,sans-serif"><span style="font-size:13.5pt"><span style="font-family:&quot;Calibri Light&quot;,sans-serif"><span style="color:#333333">iOS 12.x Safari (&uacute;ltima versi&oacute;n)</span></span></span></span></span></span></li>
	<li><span style="font-size:11pt"><span style="background-color:white"><span style="font-family:Calibri,sans-serif"><span style="font-size:13.5pt"><span style="font-family:&quot;Calibri Light&quot;,sans-serif"><span style="color:#333333">Android 9.x Chrome (&uacute;ltima versi&oacute;n)</span></span></span></span></span></span></li>
</ul>

<p style="margin-left:48px">&nbsp;</p>

<p><span style="font-size:11pt"><span style="background-color:white"><span style="font-family:Calibri,sans-serif"><strong><span style="font-size:21.0pt"><span style="font-family:&quot;Calibri Light&quot;,sans-serif"><span style="color:#333333">7. Propuestas sobre desarrollo de nuevos productos, etc.</span></span></span></strong></span></span></span></p>

<p><span style="font-size:11pt"><span style="background-color:white"><span style="font-family:Calibri,sans-serif"><span style="font-size:13.5pt"><span style="font-family:&quot;Calibri Light&quot;,sans-serif"><span style="color:#333333">Ajinomoto no puede aceptar ideas, memorandos, conceptos o cualquier otra propuesta que usted pueda enviar (en lo sucesivo, las &ldquo;Propuestas&rdquo;) con respecto al desarrollo de nuevos productos, marketing y similares. Debe saber de antemano que las Propuestas que env&iacute;e se considerar&aacute;n enviadas seg&uacute;n el siguiente entendimiento.</span></span></span></span></span></span></p>

<ol>
	<li><span style="font-size:11pt"><span style="background-color:white"><span style="font-family:Calibri,sans-serif"><span style="font-size:13.5pt"><span style="font-family:&quot;Calibri Light&quot;,sans-serif"><span style="color:#333333">Ajinomoto no es responsable de mantener la confidencialidad de las Propuestas.</span></span></span></span></span></span></li>
	<li><span style="font-size:11pt"><span style="background-color:white"><span style="font-family:Calibri,sans-serif"><span style="font-size:13.5pt"><span style="font-family:&quot;Calibri Light&quot;,sans-serif"><span style="color:#333333">Ajinomoto no tiene obligaci&oacute;n alguna de considerar o evaluar las Propuestas.</span></span></span></span></span></span></li>
	<li><span style="font-size:11pt"><span style="background-color:white"><span style="font-family:Calibri,sans-serif"><span style="font-size:13.5pt"><span style="font-family:&quot;Calibri Light&quot;,sans-serif"><span style="color:#333333">Incluso si Ajinomoto desarrolla sus ideas que son similares, total o parcialmente, a su Propuesta, Ajinomoto no tiene obligaci&oacute;n alguna con usted.</span></span></span></span></span></span></li>
</ol>

<p style="margin-left:48px">&nbsp;</p>

<p><span style="font-size:11pt"><span style="background-color:white"><span style="font-family:Calibri,sans-serif"><strong><span style="font-size:21.0pt"><span style="font-family:&quot;Calibri Light&quot;,sans-serif"><span style="color:#333333">8. Sitios enlazados</span></span></span></strong></span></span></span></p>

<p><span style="font-size:11pt"><span style="background-color:white"><span style="font-family:Calibri,sans-serif"><span style="font-size:13.5pt"><span style="font-family:&quot;Calibri Light&quot;,sans-serif"><span style="color:#333333">Ajinomoto no es responsable de ning&uacute;n da&ntilde;o que surja de los contenidos de los sitios web de terceros vinculados a este sitio web (en adelante, el &quot;Sitio web vinculado&quot;) o del uso de los sitios web vinculados.</span></span></span></span></span></span></p>

<p><span style="font-size:11pt"><span style="background-color:white"><span style="font-family:Calibri,sans-serif"><span style="font-size:13.5pt"><span style="font-family:&quot;Calibri Light&quot;,sans-serif"><span style="color:#333333">El enlace al sitio web es gratuito, en principio; siempre que, sin embargo, Ajinomoto pueda, dependiendo del contenido del sitio web vinculado o del m&eacute;todo utilizado para vincular, rechazar el enlace antes o despu&eacute;s de que se establezca el enlace (por razones tales como los contenidos son ofensivos para la pol&iacute;tica p&uacute;blica).</span></span></span></span></span></span></p>

<p><span style="font-size:11pt"><span style="background-color:white"><span style="font-family:Calibri,sans-serif"><strong><span style="font-size:21.0pt"><span style="font-family:&quot;Calibri Light&quot;,sans-serif"><span style="color:#333333">9. Cookies</span></span></span></strong></span></span></span></p>

<ol>
	<li><span style="font-size:11pt"><span style="background-color:white"><span style="font-family:Calibri,sans-serif"><span style="font-size:13.5pt"><span style="font-family:&quot;Calibri Light&quot;,sans-serif"><span style="color:#333333">El sitio web puede utilizar una tecnolog&iacute;a llamada &quot;cookies&quot; para los siguientes prop&oacute;sitos.</span></span></span></span></span></span>

	<ol>
		<li value="0"><span style="font-size:11pt"><span style="background-color:white"><span style="font-family:Calibri,sans-serif"><span style="font-size:13.5pt"><span style="font-family:&quot;Calibri Light&quot;,sans-serif"><span style="color:#333333">Para permitirle utilizar m&aacute;s convenientemente el sitio web cuando lo vuelva a visitar.</span></span></span></span></span></span></li>
		<li><span style="font-size:11pt"><span style="background-color:white"><span style="font-family:Calibri,sans-serif"><span style="font-size:13.5pt"><span style="font-family:&quot;Calibri Light&quot;,sans-serif"><span style="color:#333333">Para medir y analizar c&oacute;mo se usa el sitio web.</span></span></span></span></span></span></li>
	</ol>
	</li>
	<li><span style="font-size:11pt"><span style="background-color:white"><span style="font-family:Calibri,sans-serif"><span style="font-size:13.5pt"><span style="font-family:&quot;Calibri Light&quot;,sans-serif"><span style="color:#333333">Cuando visita contenidos que emiten cookies, las cookies se establecer&aacute;n en su computadora. Sin embargo, las cookies almacenadas no contendr&aacute;n ninguna informaci&oacute;n personalmente identificada relacionada con usted.</span></span></span></span></span></span></li>
	<li><span style="font-size:11pt"><span style="background-color:white"><span style="font-family:Calibri,sans-serif"><span style="font-size:13.5pt"><span style="font-family:&quot;Calibri Light&quot;,sans-serif"><span style="color:#333333">Al cambiar la configuraci&oacute;n en su navegador, puede negarse a aceptar cookies. Sin embargo, al hacerlo, algunas partes del sitio web pueden quedar inutilizables.</span></span></span></span></span></span></li>
</ol>

<p style="margin-left:48px">&nbsp;</p>

<p><span style="font-size:11pt"><span style="background-color:white"><span style="font-family:Calibri,sans-serif"><strong><span style="font-size:21.0pt"><span style="font-family:&quot;Calibri Light&quot;,sans-serif"><span style="color:#333333">* Herramientas anal&iacute;ticas web</span></span></span></strong></span></span></span></p>

<p><span style="font-size:11pt"><span style="background-color:white"><span style="font-family:Calibri,sans-serif"><span style="font-size:13.5pt"><span style="font-family:&quot;Calibri Light&quot;,sans-serif"><span style="color:#333333">Algunas partes del sitio web utilizan herramientas anal&iacute;ticas web para mejorar la usabilidad del sitio web para usted y proporcionar servicios de informaci&oacute;n beneficiosos. La pol&iacute;tica de privacidad de cada herramienta se puede encontrar en el siguiente sitio web.</span></span></span></span></span></span></p>

<div style="margin-bottom:11px; text-align:center">
<hr /></div>

<p><span style="font-size:11pt"><span style="background-color:white"><span style="font-family:Calibri,sans-serif"><strong><span style="font-size:27.0pt"><span style="font-family:&quot;Calibri Light&quot;,sans-serif"><span style="color:#333333">Sobre nuestras marcas registradas</span></span></span></strong></span></span></span></p>

<p><span style="font-size:11pt"><span style="background-color:white"><span style="font-family:Calibri,sans-serif"><strong><span style="font-size:21.0pt"><span style="font-family:&quot;Calibri Light&quot;,sans-serif"><span style="color:#333333">Marca registrada</span></span></span></strong></span></span></span></p>

<p><span style="font-size:11pt"><span style="background-color:white"><span style="font-family:Calibri,sans-serif"><span style="font-size:13.5pt"><span style="font-family:&quot;Calibri Light&quot;,sans-serif"><span style="color:#333333">Las siguientes son algunas marcas comerciales representativas propiedad de Ajinomoto Co., Inc.</span></span></span></span></span></span></p>

<p><span style="font-size:11pt"><span style="background-color:white"><span style="font-family:Calibri,sans-serif"><span style="font-size:13.5pt"><span style="font-family:&quot;Calibri Light&quot;,sans-serif"><span style="color:#333333">En nuestro sitio web, las marcas registradas se muestran con &reg;.</span></span></span></span></span></span></p>

<p><span style="font-size:11pt"><span style="background-color:white"><span style="font-family:Calibri,sans-serif"><span style="font-size:13.5pt"><span style="font-family:&quot;Calibri Light&quot;,sans-serif"><span style="color:#333333">Si &reg; no se puede mostrar por alguna raz&oacute;n, las marcas registradas se muestran en cursiva o con &ldquo;&rdquo; (comillas dobles).</span></span></span></span></span></span></p>

<table class="Table" style="border-bottom:solid #d7d7d7 1px; border-left:none; border-right:solid #d7d7d7 1px; border-top:none; width:557px">
	<thead>
		<tr>
			<td style="background-color:#f2f2f2; border-bottom:none; border-left:1px solid #d7d7d7; border-right:none; border-top:1px solid #d7d7d7; height:22px; width:284px">
			<p><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><strong><span style="font-size:13.5pt"><span style="font-family:&quot;Calibri Light&quot;,sans-serif"><span style="color:black">Marca</span></span></span></strong></span></span></p>
			</td>
			<td style="background-color:#f2f2f2; border-bottom:none; border-left:1px solid #d7d7d7; border-right:none; border-top:1px solid #d7d7d7; height:22px; width:267px">
			<p><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><strong><span style="font-size:13.5pt"><span style="font-family:&quot;Calibri Light&quot;,sans-serif"><span style="color:black">Categor&iacute;a</span></span></span></strong></span></span></p>
			</td>
		</tr>
	</thead>
	<tbody>
		<tr>
			<td style="background-color:#f7f7f7; border-bottom:none; border-left:1px solid #d7d7d7; border-right:none; border-top:1px solid #d7d7d7; height:22px; width:284px">
			<p><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><strong><span style="font-size:13.5pt"><span style="font-family:&quot;Calibri Light&quot;,sans-serif"><span style="color:black">Ajinomoto del Perú S.A.</span></span></span></strong></span></span></p>
			</td>
			<td style="background-color:white; border-bottom:none; border-left:1px solid #d7d7d7; border-right:none; border-top:1px solid #d7d7d7; height:22px; width:267px">
			<p><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span style="font-size:13.5pt"><span style="font-family:&quot;Calibri Light&quot;,sans-serif"><span style="color:black">Marca corporativa</span></span></span></span></span></p>
			</td>
		</tr>
		<tr>
			<td style="background-color:#f7f7f7; border-bottom:none; border-left:1px solid #d7d7d7; border-right:none; border-top:1px solid #d7d7d7; height:22px; width:284px">
			<p><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><strong><span style="font-size:13.5pt"><span style="font-family:&quot;Calibri Light&quot;,sans-serif"><span style="color:black">AjiPanda®</span></span></span></strong></span></span></p>
			</td>
			<td style="background-color:white; border-bottom:none; border-left:1px solid #d7d7d7; border-right:none; border-top:1px solid #d7d7d7; height:22px; width:267px">
			<p><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span style="font-size:13.5pt"><span style="font-family:&quot;Calibri Light&quot;,sans-serif"><span style="color:black">Embajador del Grupo Ajinomoto</span></span></span></span></span></p>
			</td>
		</tr>
		<tr>
			<td style="background-color:#f7f7f7; border-bottom:none; border-left:1px solid #d7d7d7; border-right:none; border-top:1px solid #d7d7d7; height:22px; width:284px">
			<p><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><strong><span style="font-size:13.5pt"><span style="font-family:&quot;Calibri Light&quot;,sans-serif"><span style="color:black">AJI-NO-MOTO&reg;</span></span></span></strong></span></span></p>
			</td>
			<td style="background-color:white; border-bottom:none; border-left:1px solid #d7d7d7; border-right:none; border-top:1px solid #d7d7d7; height:22px; width:267px">
			<p><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span style="font-size:13.5pt"><span style="font-family:&quot;Calibri Light&quot;,sans-serif"><span style="color:black">Sazonador umami</span></span></span></span></span></p>
			</td>
		</tr>
		<tr>
			<td style="background-color:#f7f7f7; border-bottom:none; border-left:1px solid #d7d7d7; border-right:none; border-top:1px solid #d7d7d7; height:22px; width:284px">
			<p><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><strong><span style="font-size:13.5pt"><span style="font-family:&quot;Calibri Light&quot;,sans-serif"><span style="color:black">AJI-NO-SILLAO&reg;</span></span></span></strong></span></span></p>
			</td>
			<td style="background-color:white; border-bottom:none; border-left:1px solid #d7d7d7; border-right:none; border-top:1px solid #d7d7d7; height:22px; width:267px">
			<p><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span style="font-size:13.5pt"><span style="font-family:&quot;Calibri Light&quot;,sans-serif"><span style="color:black">Salsa de soya</span></span></span></span></span></p>
			</td>
		</tr>
		<tr>
			<td style="background-color:#f7f7f7; border-bottom:none; border-left:1px solid #d7d7d7; border-right:none; border-top:1px solid #d7d7d7; height:22px; width:284px">
			<p><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><strong><span style="font-size:13.5pt"><span style="font-family:&quot;Calibri Light&quot;,sans-serif"><span style="color:black">Aji-no-men&reg; </span></span></span></strong></span></span></p>
			</td>
			<td style="background-color:white; border-bottom:none; border-left:1px solid #d7d7d7; border-right:none; border-top:1px solid #d7d7d7; height:22px; width:267px">
			<p><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span style="font-size:13.5pt"><span style="font-family:&quot;Calibri Light&quot;,sans-serif"><span style="color:black">Sopas instant&aacute;neas</span></span></span></span></span></p>
			</td>
		</tr>
		<tr>
			<td style="background-color:#f7f7f7; border-bottom:none; border-left:1px solid #d7d7d7; border-right:none; border-top:1px solid #d7d7d7; height:22px; width:284px">
			<p><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><strong><span style="font-size:13.5pt"><span style="font-family:&quot;Calibri Light&quot;,sans-serif"><span style="color:black">Aji-no-men&reg; Tallar&iacute;n instant&aacute;neo</span></span></span></strong></span></span></p>
			</td>
			<td style="background-color:white; border-bottom:none; border-left:1px solid #d7d7d7; border-right:none; border-top:1px solid #d7d7d7; height:22px; width:267px">
			<p><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span style="font-size:13.5pt"><span style="font-family:&quot;Calibri Light&quot;,sans-serif"><span style="color:black">mezcla de sazonador</span></span></span></span></span></p>
			</td>
		</tr>
		<tr>
			<td style="background-color:#f7f7f7; border-bottom:none; border-left:1px solid #d7d7d7; border-right:none; border-top:1px solid #d7d7d7; height:22px; width:284px">
			<p><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><strong><span style="font-size:13.5pt"><span style="font-family:&quot;Calibri Light&quot;,sans-serif"><span style="color:black">Do&ntilde;a Gusta&reg;</span></span></span></strong></span></span></p>
			</td>
			<td style="background-color:white; border-bottom:none; border-left:1px solid #d7d7d7; border-right:none; border-top:1px solid #d7d7d7; height:22px; width:267px">
			<p><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span style="font-size:13.5pt"><span style="font-family:&quot;Calibri Light&quot;,sans-serif"><span style="color:black">Caldo concentrado en polvo</span></span></span></span></span></p>
			</td>
		</tr>
		<tr>
			<td style="background-color:#f7f7f7; border-bottom:none; border-left:1px solid #d7d7d7; border-right:none; border-top:1px solid #d7d7d7; height:22px; width:284px">
			<p><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><strong><span style="font-size:13.5pt"><span style="font-family:&quot;Calibri Light&quot;,sans-serif"><span style="color:black">Aji-no-mix&reg; Deli Arroz</span></span></span></strong></span></span></p>
			</td>
			<td style="background-color:white; border-bottom:none; border-left:1px solid #d7d7d7; border-right:none; border-top:1px solid #d7d7d7; height:22px; width:267px">
			<p><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span style="font-size:13.5pt"><span style="font-family:&quot;Calibri Light&quot;,sans-serif"><span style="color:black">Sazonador para arroz</span></span></span></span></span></p>
			</td>
		</tr>
		<tr>
			<td style="background-color:#f7f7f7; border-bottom:none; border-left:1px solid #d7d7d7; border-right:none; border-top:1px solid #d7d7d7; height:22px; width:284px">
			<p><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><strong><span style="font-size:13.5pt"><span style="font-family:&quot;Calibri Light&quot;,sans-serif"><span style="color:black">Aji-no-mix&reg; Mezcla para Apanar</span></span></span></strong></span></span></p>
			</td>
			<td style="background-color:white; border-bottom:none; border-left:1px solid #d7d7d7; border-right:none; border-top:1px solid #d7d7d7; height:22px; width:267px">
			<p><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span style="font-size:13.5pt"><span style="font-family:&quot;Calibri Light&quot;,sans-serif"><span style="color:black">Mezcla para apanar</span></span></span></span></span></p>
			</td>
		</tr>
		<tr>
			<td style="background-color:#f7f7f7; border-bottom:none; border-left:1px solid #d7d7d7; border-right:none; border-top:1px solid #d7d7d7; height:22px; width:284px">
			<p><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><strong><span style="font-size:13.5pt"><span style="font-family:&quot;Calibri Light&quot;,sans-serif"><span style="color:black">Aji-no-mix&reg; CHIFA</span></span></span></strong></span></span></p>
			</td>
			<td style="background-color:white; border-bottom:none; border-left:1px solid #d7d7d7; border-right:none; border-top:1px solid #d7d7d7; height:22px; width:267px">
			<p><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span style="font-size:13.5pt"><span style="font-family:&quot;Calibri Light&quot;,sans-serif"><span style="color:black">Sazonador para Chifa</span></span></span></span></span></p>
			</td>
		</tr>
		<tr>
			<td style="background-color:#f7f7f7; border-bottom:none; border-left:1px solid #d7d7d7; border-right:none; border-top:1px solid #d7d7d7; height:22px; width:284px">
			<p><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><strong><span style="font-size:13.5pt"><span style="font-family:&quot;Calibri Light&quot;,sans-serif"><span style="color:black">Aji-no-mix&reg; Ablanda-Saz&oacute;n</span></span></span></strong></span></span></p>
			</td>
			<td style="background-color:white; border-bottom:none; border-left:1px solid #d7d7d7; border-right:none; border-top:1px solid #d7d7d7; height:22px; width:267px">
			<p><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span style="font-size:13.5pt"><span style="font-family:&quot;Calibri Light&quot;,sans-serif"><span style="color:black">Ablandador de carne</span></span></span></span></span></p>
			</td>
		</tr>
		<tr>
			<td style="background-color:#f7f7f7; border-bottom:none; border-left:1px solid #d7d7d7; border-right:none; border-top:1px solid #d7d7d7; height:22px; width:284px">
			<p><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><strong><span style="font-size:13.5pt"><span style="font-family:&quot;Calibri Light&quot;,sans-serif"><span style="color:black">misk&iacute;simoo&reg; </span></span></span></strong></span></span></p>
			</td>
			<td style="background-color:white; border-bottom:none; border-left:1px solid #d7d7d7; border-right:none; border-top:1px solid #d7d7d7; height:22px; width:267px">
			<p><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span style="font-size:13.5pt"><span style="font-family:&quot;Calibri Light&quot;,sans-serif"><span style="color:black">Chocolatada en polvo</span></span></span></span></span></p>
			</td>
		</tr>
		<tr>
			<td style="background-color:#f7f7f7; border-bottom:1px solid #d7d7d7; border-left:1px solid #d7d7d7; border-right:none; border-top:1px solid #d7d7d7; height:22px; width:284px">
			<p><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><strong><span style="font-size:13.5pt"><span style="font-family:&quot;Calibri Light&quot;,sans-serif"><span style="color:black">KEMAL KRUKER&reg;</span></span></span></strong></span></span></p>
			</td>
			<td style="background-color:white; border-bottom:1px solid #d7d7d7; border-left:1px solid #d7d7d7; border-right:none; border-top:1px solid #d7d7d7; height:22px; width:267px">
			<p><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span style="font-size:13.5pt"><span style="font-family:&quot;Calibri Light&quot;,sans-serif"><span style="color:black">Vinagre de manzana</span></span></span></span></span></p>
			</td>
		</tr>
		<tr>
			<td style="background-color:#f7f7f7; border-bottom:1px solid #d7d7d7; border-left:1px solid #d7d7d7; border-right:none; border-top:1px solid #d7d7d7; height:22px; width:284px">
			<p><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><strong><span style="font-size:13.5pt"><span style="font-family:&quot;Calibri Light&quot;,sans-serif"><span style="color:black">AJI-NO-FER&reg;</span></span></span></strong></span></span></p>
			</td>
			<td style="background-color:white; border-bottom:1px solid #d7d7d7; border-left:1px solid #d7d7d7; border-right:none; border-top:1px solid #d7d7d7; height:22px; width:267px">
			<p><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span style="font-size:13.5pt"><span style="font-family:&quot;Calibri Light&quot;,sans-serif"><span style="color:black">Fertilizante l&iacute;quido</span></span></span></span></span></p>
			</td>
		</tr>
		<tr>
			<td style="background-color:#f7f7f7; border-bottom:1px solid #d7d7d7; border-left:1px solid #d7d7d7; border-right:none; border-top:1px solid #d7d7d7; height:22px; width:284px">
			<p><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><strong><span style="font-size:13.5pt"><span style="font-family:&quot;Calibri Light&quot;,sans-serif"><span style="color:black">AJIFOL&reg;</span></span></span></strong></span></span></p>
			</td>
			<td style="background-color:white; border-bottom:1px solid #d7d7d7; border-left:1px solid #d7d7d7; border-right:none; border-top:1px solid #d7d7d7; height:22px; width:267px">
			<p><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span style="font-size:13.5pt"><span style="font-family:&quot;Calibri Light&quot;,sans-serif"><span style="color:black">Fertilizante foliar</span></span></span></span></span></p>
			</td>
		</tr>
		<tr>
			<td style="background-color:#f7f7f7; border-bottom:none; border-left:1px solid #d7d7d7; border-right:none; border-top:1px solid #d7d7d7; height:22px; width:284px">
			<p><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><strong><span style="font-size:13.5pt"><span style="font-family:&quot;Calibri Light&quot;,sans-serif"><span style="color:black">ACTIVA&reg;</span></span></span></strong></span></span></p>
			</td>
			<td style="background-color:white; border-bottom:none; border-left:1px solid #d7d7d7; border-right:none; border-top:1px solid #d7d7d7; height:22px; width:267px">
			<p><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span style="font-size:13.5pt"><span style="font-family:&quot;Calibri Light&quot;,sans-serif"><span style="color:black">Enzimas</span></span></span></span></span></p>
			</td>
		</tr>
	</tbody>
</table>

<p>&nbsp;</p>`;
        return (
            <div className="container container__pages py-5">
                <h2 className='text-center mb-4'>Términos de Uso</h2>
                <div>
                    {ReactHtmlParser(htmlBody)}
                </div>
            </div>
        );
    }
}