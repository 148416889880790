import React, { Component } from 'react';
import axios from 'axios';
import { SERVICE_URL, SERVICE_IMG } from '../constants/global';
import { IProductoItem } from '../models/IProductoItem';
import { Link } from 'react-router-dom';
import './RecetaDetail.css';


export interface IProductoCategoriaProps {
    idCategoria: number;
}

export interface IProductoCategoriaState {
    items: Array<IProductoItem>;
}

export class ProductoCategoria extends Component<IProductoCategoriaProps, IProductoCategoriaState> {
    private params: any;
    static displayName = ProductoCategoria.name;

    constructor(props: any) {
        super(props);

        this.state = {
            items:[]
        }
    }

    componentDidMount() {
        this.getData(this.props.idCategoria);
    }

    getData(idCategoria : number) {
        var Query = "/api/Producto/ByCategoria/" + idCategoria;
        var urlServicio = SERVICE_URL + Query;
        axios.get(urlServicio).then(res => {
            var data = res.data;
            console.log(data);
            this.setState({ items: data })
        })
    }

    render() {
        return (
            <div>
                <div className="row row-cols-1 row-cols-md-3 g-4">
                    {this.state.items.map(function (d) {
                        let verCarrito = { };
                        if (d.tiendas.length > 0) {
                            verCarrito = { display: "inline-grid" };
                        }
                        else {
                            verCarrito = { display: "none" };
                        }

                        if (d.activo) {
                            if (d.tiendas.length > 0) {
                                return (
                                    <div key={d.urlSlug} className="col mb-5">
                                        <div>
                                            <Link to={{ pathname: '/producto/' + d.urlSlug }} > <div className="img__header__content" style={{ backgroundImage: 'url(' + SERVICE_IMG + d.rutaMiniatura + ')' }}></div></Link>
                                            <div className="mt-4 row">
                                                <div className="col-lg-10">
                                                    <Link to={{ pathname: '/producto/' + d.urlSlug }} > <h5 className="card-title history__card__title">{d.titulo}</h5></Link>
                                                </div>
                                                <div className="col-lg-2 carrito__container" style={verCarrito}>
                                                    <img src="../images/ico_carrito.svg" className="carrito__img" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                            else {
                                return (
                                    <div key={d.urlSlug} className="col mb-5">
                                        <div>
                                            <Link to={{ pathname: '/producto/' + d.urlSlug }} > <div className="img__header__content" style={{ backgroundImage: 'url(' + SERVICE_IMG + d.rutaMiniatura + ')' }}></div></Link>
                                            <div className="mt-4 row">
                                                <div className="col-lg-12">
                                                    <Link to={{ pathname: '/producto/' + d.urlSlug }} > <h5 className="text-center card-title history__card__title">{d.titulo}</h5></Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                            
                        }
                    })}

                </div>
            </div>
        );
    }
}
