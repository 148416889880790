import React, { Component } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { SERVICE_URL, SERVICE_IMG } from '../constants/global';

export class Nosotros extends Component {
  static displayName = Nosotros.name;

  constructor(props) {
      super(props);

      this.state = {
          items: [],
      };
    }

    componentDidMount() {
        this.getData();
    }

    getData() {
        var Query = "/api/Nosotros";
        var urlServicio = SERVICE_URL + Query;
        axios.get(urlServicio).then(res => {
            var data = res.data;
            //console.log(data);
            this.setState({ items: data })
        })
    }

  render() {
    return (
        <div>
            <header className="masthead">
                <img src="../images/bg_sobre_nosotros.jpg" className="d-block img-fluid" alt="Header" />
                <div className="carousel-caption">
                    <h1 className="display-2">Sobre nosotros</h1>
                    <h3>En Ajinomoto del Per&#250; S.A.estamos comprometidos con la buena alimentaci&#243;n, el bienestar de la sociedad y el medioambiente.</h3>
                </div>
                
            </header>
            
            <div className="container py-5">
                <div className="row row-cols-1 row-cols-md-3 g-4">
                    {this.state.items.map(function (d) {
                        var urlImagen = "";
                        if (d.rutaMiniatura != null && d.rutaMiniatura != undefined && d.rutaMiniatura != "") {
                            urlImagen = d.rutaMiniatura;
                        }
                        else {
                            urlImagen = d.rutaImagen;
                        }

                        var urlRoot = "";
                        if (d.esPersonalizado) {
                            urlRoot = '/' + d.urlSlug;
                        }
                        else {
                            urlRoot = '/nosotros/' + d.urlSlug;
                        }

                        if (d.activo) {
                            return (
                                <div key={urlRoot} className="col mb-5">
                                    <div>
                                        <Link to={{ pathname: urlRoot }} ><div className="img__header__content" style={{ backgroundImage: 'url(' + SERVICE_IMG + urlImagen + ')' }}></div></Link>
                                        <div className="mt-3">
                                            <Link to={{ pathname: urlRoot }} ><h5 className="card-title titleopcion">{d.titulo}</h5></Link>
                                            <p className="card-text description_option">{d.resumen}</p>
                                        </div>
                                    </div>
                                </div>
                            )
                        }                        
                    })}
                </div>
            </div>
        </div>
    );
  }
}
