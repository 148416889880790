import React from 'react';
import BuscadorResultado from '../components/BuscadorResultado';

const BuscadorList = ({ resultados }) => {
    return (
        <div className="g-4">
            {resultados && resultados.map((resultado) => <BuscadorResultado key={resultado.titulo} {...resultado} />)}
        </div>
    );
};

export default BuscadorList;
