import React, { Component } from 'react';

export class Reportes extends Component {
    static displayName = Reportes.name;


    render() {
        return (
            <div>
                <header className="masthead">
                    <img src="../images/bg_reportes.jpg" className="img-fluid" alt="Header" />
                </header>
                <div className="container py-5">                    
                    <div className="container text-center mb-4">
                        <h3 className="fw-light">Reportes</h3>
                    </div>
                </div>
            </div>
        );
    }
}
