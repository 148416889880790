import React, { Component } from 'react';

class Error extends Component {
  render() {
    return (
      <div className="page">
        <p>Página no encontrada</p>
      </div>
    );
  }
}

export default Error;