import React, { Component, useEffect, useState } from "react";
import axios from 'axios';
import { SERVICE_URL, SERVICE_IMG } from '../constants/global';
import { Form } from "react-bootstrap";
import { ConsultaTipoNegocio } from './../components/ConsultaTipoNegocio';
import { ConsultaProductoRuta2 } from './../components/ConsultaProductoRuta2';

export const ConsultasMotivo = () => {
    const [vlListValores, setListValores] = useState([]);
    const [vlVerProducto, setVerProducto] = useState(false);
    const [vlVerTipoNegocio, setVerTipoNegocio] = useState(false);

    var Query = "/api/ContactoAsunto";
    var urlServicio = SERVICE_URL + Query;

    useEffect(() => {
        axios
            .get(urlServicio)
            .then((response) => {
                if (response.status === 200) {
                    setListValores(response?.data);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    });

    const handleChange = event => {
        var index = event.nativeEvent.target.selectedIndex;
        var valueSeleccionado = event.nativeEvent.target[index].text;

        if (valueSeleccionado === 'Quejas o reclamos') {
            setVerProducto(true);
        }
        else {
            setVerProducto(false);
        }

        if (valueSeleccionado === 'Ventas') {
            setVerTipoNegocio(true);
        }
        else {
            setVerTipoNegocio(false);
        }
    };

    return (
        <div>
            <div className="form-group">
                <Form.Select defaultValue={""} onChange={handleChange} className="form-control consulta__input" placeholder="Selecciona una opción" id="contact__asunto" required>
                    <option value=""> Selecciona un motivo</option>
                    {vlListValores.map(function (item, key) {
                        return (
                            <option key={key} value={item.id}>{item.titulo}</option>
                        );
                    })}
                </Form.Select>
            </div>
            {vlVerTipoNegocio ? <ConsultaTipoNegocio /> : ''}
            {vlVerProducto ? <ConsultaProductoRuta2 /> : ''}            
        </div>
    );   
    
}