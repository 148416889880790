import React, { Component } from 'react';
import axios from 'axios';
import { SERVICE_URL, SERVICE_IMG } from '../constants/global';
import ReactHtmlParser from 'react-html-parser';
import { IProductoItem } from '../models/IProductoItem';
import { LineaNegocioOptions } from './LineaNegocioOptions';
import { ProductoCategoria } from './../components/ProductoCategoria';
import { Link } from 'react-router-dom';
import './RecetaDetail.css';
import moment from 'moment';
moment.locale("es");

export interface ILineaNegocioDetailProps {
    url: string;
}

export interface ILineaNegocioDetailState {
    items: Array<IProductoItem>;
}

export class LineaNegocioDetail extends Component<any, ILineaNegocioDetailState> {
    private params: any;
    static displayName = LineaNegocioDetail.name;

    constructor(props: any) {
        super(props);

        this.state = {
            items:[]
        }
    }

    componentDidMount() {
        this.getData(this.props.match.params.url);
    }

    getData(url: string) {       

        var Query = "/api/Producto/AllCategorias/ByLineaNegocioUrl/" + url;
        var urlServicio = SERVICE_URL + Query;
        axios.get(urlServicio).then(res => {
            var data = res.data;
            console.log(data);

            //let hash = {};
            //var array = data.filter(o => hash[o.idCategoria] ? false : hash[o.idCategoria] = true);
            //console.log(array);

            //const unique = [...new Set(data.map(item => item.nombreCategoria))];
            //console.log(unique);
            

            this.setState({ items: data })
        })
    }

    render() {
        return (
            <div>
                <header className="masthead">
                    <img src="../images/bg_lineasnegocio.jpg" className="img-fluid" alt="Header" />
                    <div className="carousel-caption">
                        <h1 className="display-2">L&#237;neas de negocio</h1>
                        <h3>Contamos con productos seleccionados de alta calidad para todo nuestro p&#250;blico peruano.</h3>
                    </div>
                </header>
                <LineaNegocioOptions url={this.props.match.params.url}/>
                <div className="container">
                    {this.state.items.map(function (d) {
                        return (
                            <><h5 className="card-title title__receta mb-4 text-left">{d.titulo}</h5>
                                <ProductoCategoria idCategoria={d.id} /></>
                        )
                    })}                  
                    
                </div>
            </div>
        );
    }
}
