import React, { Component } from 'react';
import axios from 'axios';
import { SERVICE_URL, SERVICE_IMG } from '../constants/global';
import ReactHtmlParser from 'react-html-parser';

export interface IPaginaDetailProps {
    url: string;
}

export interface IPaginaDetailState {
    id: number,
    titulo: string,
    contenido: string,
    rutaArchivo: string,
    rutaMiniatura: string,
    urlSlug: string,
    activo: boolean
}

export class PaginaDetail extends Component<any, IPaginaDetailState> {
    private params: any;
    static displayName = PaginaDetail.name;

    constructor(props: any) {
        super(props);

        this.state = {
            id: 0,
            titulo: '',
            contenido: '',
            rutaArchivo: '',
            rutaMiniatura: '',
            urlSlug: '',
            activo: true
        }
    }

    componentDidMount() {
        this.getData(this.props.match.params.url);
    }

    getData(url: string) {
        var Query = "/api/Pagina/ByUrl/" + url;
        var urlServicio = SERVICE_URL + Query;
        axios.get(urlServicio).then(res => {
            var data = res.data;
            console.log(data);
            this.setState({
                id: data.id,
                titulo: data.titulo,
                contenido: data.contenido,
                rutaArchivo: data.rutaArchivo,
                rutaMiniatura: data.rutaMiniatura,
                urlSlug: data.urlSlug,
                activo: true
            })
        })
    }

    render() {
        var urlImagen = "";
        if (this.state.rutaArchivo != null && this.state.rutaArchivo != undefined && this.state.rutaArchivo != "") {
            urlImagen = this.state.rutaArchivo;
        }
        else {
            urlImagen = this.state.rutaMiniatura;
        }

        return (
            <div>
                <div className="container container__pages py-5">
                    <h2 className="mb-4 text-center">{this.state.titulo}</h2>
                    <img src={SERVICE_IMG + urlImagen} className="img-fluid" alt="Header" />
                    <div className="mb-4 mt-4">
                        <div>
                            {ReactHtmlParser(this.state.contenido)}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
