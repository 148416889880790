import React, { Component } from 'react';
import axios from 'axios';
import { SERVICE_URL, SERVICE_IMG } from '../constants/global';
import { Link } from 'react-router-dom';

export class Sostenibilidad extends Component {
    static displayName = Sostenibilidad.name;

    constructor(props) {
        super(props);

        this.state = {
            items: [],
        };
    }

    componentDidMount() {
        this.getData();
    }

    getData() {
        var Query = "/api/NutricionNavegacion";
        var urlServicio = SERVICE_URL + Query;
        axios.get(urlServicio).then(res => {
            var data = res.data;
            //console.log(data);
            this.setState({ items: data })
        })
    }

    render() {
        return (
            <div>
                <header className="masthead">
                    <img src="../images/foto-cabecera-sostenibilidad.jpg" className="img-fluid" alt="Header" />
                    <div className="carousel-caption">
                        <h1 className="display-2">Sostenibilidad</h1>
                        <h3>Tenemos la convicción que una gestión sostenible es fundamental para garantizar la preservación de los
                            recursos naturales. Es por ello que planificamos e implementamos actividades que contribuyen al cuidado
                            del planeta.</h3>
                    </div>
                </header>
                <div className="container py-5">
                    <h2 class="fw-light text-center mb-5">Nutrición</h2>
                    <div className="row row-cols-1 row-cols-md-3 g-4">
                        {this.state.items.map(function (d) {
                            var urlImagen = "";
                            if (d.rutaMiniatura != null && d.rutaMiniatura != undefined && d.rutaMiniatura != "") {
                                urlImagen = d.rutaMiniatura;
                            }
                            else {
                                urlImagen = d.rutaArchivo;
                            }
                            return (
                                <div key={d.id} className="col mb-5">
                                    <div>
                                        <Link to={{ pathname: '/' + d.url }} ><div className="img__header__content" style={{ backgroundImage: 'url(' + SERVICE_IMG + urlImagen + ')' }}></div></Link>
                                        <div className="mt-3">
                                            <Link to={{ pathname: '/' + d.url }} ><h5 className="card-title titleopcion">{d.titulo}</h5></Link>
                                            <p className="card-text description_option">{d.descripcion}</p>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    
                    <h2 class="fw-light text-center mb-5">Medio Ambiente</h2>
                    <div className="row row-cols-1 row-cols-md-3 g-4">
                        <div class="col mb-5">
                            <div>
                                <a href="/reporte-sostenibilidad">
                                    <div class="img__header__content" style={{
                                        backgroundImage: 'url(/images/MINIATURA-reporte.jpg)'
                                    }}></div>
                                </a>
                                <div class="mt-3">
                                    <a href="/reporte-sostenibilidad">
                                        <h5 class="card-title titleopcion">Reportes de sostenibilidad</h5>
                                    </a>
                                    <p class="card-text description_option">En esta sección presentamos nuestro desempeño en sostenibilidad a nivel local y global.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}