import React, { Component } from 'react';
import axios from 'axios';
import { SERVICE_URL, SERVICE_IMG } from '../constants/global';
import { Link } from 'react-router-dom';
import Carrousel from "react-slick";
import { Modal, ModalBody, ModalHeader, Button } from 'reactstrap';
import "../../node_modules/slick-carousel/slick/slick.css";
import "../../node_modules/slick-carousel/slick/slick-theme.css";
import './Tienda.css';

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", zIndex: 99999 }}
            onClick={onClick}
        />
    );
}
function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", zIndex: 99999 }}
            onClick={onClick}
        />
    );
}

export class Tienda extends Component {
    static displayName = Tienda.name;
    constructor(props) {
        super(props);

        this.state = {
            items: [],
        };
    }
    componentDidMount() {
        this.getData();
    }

    getData() {
        var Query = "/api/Tienda";
        var urlServicio = SERVICE_URL + Query;
        axios.get(urlServicio).then(res => {
            var data = res.data;
            console.log(data);
            this.setState({ items: data })
        })
    }

    render() {
        var settings = {
            dots: true,
            infinite: false,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 3,
            autoplay: true,
            autoplaySpeed: 10000,
            nextArrow: <SampleNextArrow />,
            prevArrow: <SamplePrevArrow />,
            responsive: [
                {
                    breakpoint: 1900,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 4,
                    }
                },
                {
                    breakpoint: 1300,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                    }
                },
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                    }
                },
                {
                    breakpoint: 640,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    }
                }
            ]
        };
        return (
            <div>
                <Modal isOpen={true} size="lg">
                    <ModalHeader toggle={function noRefCheck() { }}>
                        Comprar ahora en:
                    </ModalHeader>
                    <ModalBody>                        
                        <Carrousel {...settings}>
                            {this.state.items.map(function (d) {
                                return (
                                    <div>
                                        <div className="container__tienda__item">
                                            <img src={SERVICE_IMG + d.rutaArchivo} className="img-fluid" />
                                            <div className="container__carrito__title">
                                                <p className="container__tienda__item__title text-center mb-4 mt-4">{d.titulo}</p>
                                            </div>
                                        </div>
                                        <div className="mb-3 mt-3">
                                            <div className="carrito__container__comprar">
                                                <img src="../images/ico_comprar_ahora.svg" className="img__carrito__comprar" />
                                                <a target="_blank" rel="noopener" href={d.url}>COMPRAR AHORA</a>                                               
                                            </div>
                                        </div>
                                    </div>                                  
                                )
                            })}
                        </Carrousel>
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}