import React, { Component } from 'react';
import axios from 'axios';
import { SERVICE_URL } from '../constants/global';
import { MenuSub } from './../components/MenuSub';

export class Menu extends Component {
    static displayName = Menu.name;

    constructor(props) {
        super(props);

        this.state = {
            items: [],
        };
    }
    /*
    componentDidMount() {
        this.getData();
    }

    getData() {
        var Query = "/api/HomeMenu/GetAllWithHomeSubMenu";
        var urlServicio = SERVICE_URL + Query;
        axios.get(urlServicio).then(res => {
            var data = res.data;
            this.setState({ items: data })
        })
    }*/

    render() {
        return (
            <ul class="navbar-nav flex-grow">
                <li class="has-megamenu"><button class="nav-link dropdown-toggle text-dark" type="button"
                    data-toggle="dropdown">Sobre Nosotros</button>
                    <div class="dropdown-menu megamenu">
                        <div class="container mb-5">
                            <div class="col-megamenu">
                                <h6 class="mb-3"><a class="submenu__title" href="/sobre-nosotros">Sobre Nosotros</a></h6>
                                <div class="form-group">
                                    <ul class="list-unstyled ml-3">
                                        <li class="mb-1"><a class="submenu__option" href="/nosotros/ajinomoto-peru">Ajinomoto del
                                            Perú</a></li>
                                        <li class="mb-1"><a class="submenu__option" href="/nosotros/ajinomoto-group">Ajinomoto
                                            Group</a></li>
                                        <li class="mb-1"><a class="submenu__option" href="/nuestra-historia">Historia</a></li>
                                        <li class="mb-1"><a class="submenu__option"
                                            href="/nosotros/valor-compartido-ajinomoto">Valor compartido Ajinomoto (ASV)</a>
                                        </li>
                                        <li class="mb-1"><a class="submenu__option" href="/politica-calidad">Política de calidad</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
                <li class="has-megamenu"><button class="nav-link dropdown-toggle text-dark" type="button"
                    data-toggle="dropdown">Líneas de Negocio</button>
                    <div class="dropdown-menu megamenu">
                        <div class="container mb-5">
                            <div class="col-megamenu">
                                <h6 class="mb-3"><a class="submenu__title" href="/lineas-negocio">Líneas de Negocio</a></h6>
                                <div class="form-group">
                                    <ul class="list-unstyled ml-3">
                                        <li class="mb-1"><a class="submenu__option" href="/linea-negocio/consumo-masivo">Consumo
                                            masivo</a></li>
                                        <li class="mb-1"><a class="submenu__option" href="/linea-negocio/food-service">Food
                                            service</a></li>
                                        <li class="mb-1"><a class="submenu__option" href="/linea-negocio/ventas-industriales">Ventas
                                            industriales</a></li>
                                        <li class="mb-1"><a class="submenu__option"
                                            href="/linea-negocio/nutricion-vegetal">Nutrición vegetal</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
                <li class="has-megamenu"><a className="nav-link dropdown-toggle text-dark" href={"/recetas"}>Recetas</a></li>
                <li class="has-megamenu"><button class="nav-link dropdown-toggle text-dark" type="button"
                    data-toggle="dropdown">Umami</button>
                    <div class="dropdown-menu megamenu">
                        <div class="container mb-5">
                            <div class="col-megamenu">
                                <h6 class="mb-3"><a class="submenu__title" href="/umami">Umami</a></h6>
                                <div class="form-group">
                                    <ul class="list-unstyled ml-3">
                                        <li class="mb-1"><a class="submenu__option" href="/categoria/que-es-umami">¿Qué es
                                            umami?</a></li>
                                        <li class="mb-1"><a class="submenu__option" href="/categoria/descubrimiento-del-umami">Más
                                            de 100 años desde su descubrimiento</a></li>
                                        <li class="mb-1"><a class="submenu__option"
                                            href="/categoria/donde-encontramos-el-gusto-umami">¿Dónde encontramos el gusto
                                            umami?</a></li>
                                        <li class="mb-1"><a class="submenu__option"
                                            href="/categoria/experimenta-el-umami">Experimenta el umami</a></li>
                                        <li class="mb-1"><a class="submenu__option"
                                            href="/categoria/beneficios-del-gusto-umami">Beneficios del gusto umami</a></li>
                                        <li class="mb-1"><a class="submenu__option" href="/categoria/que-es-el-gms">¿Qué es el
                                            GMS?</a></li>
                                        <li class="mb-1"><a class="submenu__option" href="/categoria/como-se-produce-el-gms">¿Cómo
                                            se produce el GMS? (AJI-NO-MOTO)</a></li>
                                        <li class="mb-1"><a class="submenu__option"
                                            href="/categoria/caracteristicas-modo-de-uso-del-gms">Características y modo de uso
                                            del GMS</a></li>
                                        <li class="mb-1"><a class="submenu__option" href="/categoria/beneficios-del-gms">Beneficios
                                            del GMS</a></li>
                                        <li class="mb-1"><a class="submenu__option" href="/categoria/inocuidad-del-gms">Inocuidad
                                            del GMS</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
                <li class="has-megamenu"><button class="nav-link dropdown-toggle text-dark" type="button"
                    data-toggle="dropdown">Sostenibilidad</button>
                    <div class="dropdown-menu megamenu">
                        <div class="container mb-5">
                            <div class="col-megamenu">
                                <h6 class="mb-3"><a class="submenu__title" href="/sostenibilidad">Sostenibilidad</a></h6>
                                <div class="form-group">
                                    <div>
                                        <a class="submenu__option" href="/nutricion">Nutrición</a>
                                        <ul class="list-unstyled ml-3">
                                            <li class="mb-1"><a class="submenu__option" href="/nutricion-bienestar-det/el-compromiso-con-la-nutricion-del-grupo-ajinomoto">Compromiso del Grupo
                                                Ajinomoto con la nutrición</a></li>
                                            <li class="mb-1"><a class="submenu__option" href="/nutricion-bienestar-det/programa-nutriwork">Nutriwork</a></li>
                                            <li class="mb-1"><a class="submenu__option" href="/recetas">Recetas</a></li>
                                            <li class="mb-1"><a class="submenu__option" href="/pagina/proyecto-menos-sal-y-mas-sabor">Proyecto Menos sal y Más sabor
                                            </a></li>
                                        </ul>
                                    </div>
                                    <div>
                                        <a class="submenu__option" href="/sostenibilidad-det/medio-ambiente">Medio
                                            Ambiente</a>
                                        <ul class="list-unstyled ml-3">
                                            <li class="mb-1"><a class="submenu__option" href="/reporte-sostenibilidad">Reporte de
                                                Sostenibilidad</a></li>
                                        </ul>
                                    </div>
                                    <div></div>
                                    <div></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
                <li class="has-megamenu"><button class="nav-link dropdown-toggle text-dark" type="button"
                    data-toggle="dropdown">Noticias</button>
                    <div class="dropdown-menu megamenu">
                        <div class="container mb-5">
                            <div class="col-megamenu">
                                <h6 class="mb-3"><a class="submenu__title" href="/actividades">Noticias</a></h6>
                                <div class="form-group">
                                    <ul class="list-unstyled ml-3">
                                        <li class="mb-1"><a class="submenu__option" href="/noticias">Noticias</a></li>
                                        <li class="mb-1"><a class="submenu__option" href="/historias">Historias</a></li>
                                        <li class="mb-1"><a class="submenu__option" href="/videos">Videos</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>

            /*<ul className="navbar-nav flex-grow">
                {this.state.items.map(function (item, key) {
                    if (item.homeSubMenu.length > 0) {
                        return (
                            <li className="has-megamenu">
                                <button className="nav-link dropdown-toggle text-dark" type="button" data-toggle="dropdown">{item.titulo}</button>
                                <div className="dropdown-menu megamenu">
                                    <div className="container mb-5">
                                        <div className="col-megamenu">
                                            <h6 className="mb-3"><a className="submenu__title" href={"/" + item.url}>{item.titulo}</a></h6>
                                            <MenuSub peIdMenu={item.id} />
                                        </div>
                                    </div>
                                </div>
                            </li>
                        );
                    }
                    else {
                        return (
                            <li className="has-megamenu">
                                <a className="nav-link dropdown-toggle text-dark" href={"/" + item.url}>{item.titulo}</a>
                            </li>
                        );
                    }
                })}
            </ul>*/
        );
    }
}