import React, { Component } from 'react';
import axios from 'axios';
import { SERVICE_URL, SERVICE_IMG } from '../constants/global';
import { Link } from 'react-router-dom';

export interface IUmamiDetail {
    id: string;
    titulo: string;
    resumen: string;
    rutaArchivo: string;
    rutaMiniatura: string;
    urlSlug: string;
}

export interface IUmamiDetailProps {
    idCategoria: any;
}

export interface IUmamiDetailState {
    items: Array<IUmamiDetail>;
}

export class UmamiCategoria extends Component<IUmamiDetailProps, IUmamiDetailState> {
    static displayName = UmamiCategoria.name;

    constructor(props: IUmamiDetailProps) {
        super(props);

        this.state = {
            items: [],
        };
    }

    componentDidMount() {
        this.getData(this.props.idCategoria);
    }

    getData(idCategoria:string) {
        var Query = "/api/Umami/ByCategoria/" + idCategoria;
        var urlServicio = SERVICE_URL + Query;
        axios.get(urlServicio).then(res => {
            var data = res.data;
            //console.log(data);
            this.setState({ items: data })
        })
    }

    render() {
        return (
            <div>
                <div className="row row-cols-1 row-cols-md-3 g-4">
                    {this.state.items.map(function (d) {
                        var urlImagen = "";
                        if (d.rutaMiniatura != null && d.rutaMiniatura != undefined && d.rutaMiniatura != "") {
                            urlImagen = d.rutaMiniatura;
                        }
                        else {
                            urlImagen = d.rutaArchivo;
                        }
                        return (
                            <div key={d.id} className="col mb-5">
                                <div>
                                    <Link to={{ pathname: '/categoria/' + d.urlSlug }} ><div className="img__header__content" style={{ backgroundImage: 'url(' + SERVICE_IMG + urlImagen + ')' }}></div></Link>                                    
                                    <div className="mt-3">
                                        <Link to={{ pathname: '/categoria/' + d.urlSlug }} ><h5 className="card-title titleopcion">{d.titulo}</h5></Link>
                                        <p className="card-text description_option">{d.resumen}</p>
                                    </div>
                                </div>
                            </div>
                        )
                    })}

                </div>
            </div>
        );
    }
}
