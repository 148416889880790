import React, { Component } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { SERVICE_URL, SERVICE_IMG } from '../constants/global';
import Carrousel from "react-slick";
import $ from "jquery";
import "../../node_modules/slick-carousel/slick/slick.css";
import "../../node_modules/slick-carousel/slick/slick-theme.css";

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", zIndex: 99999 }}
            onClick={onClick}
        />
    );
}
function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", zIndex: 99999 }}
            onClick={onClick}
        />
    );
}

export interface ILineaNegocioOptionsItem {
    id: number,
    titulo: string,
    urlSlug: string,
}

export interface ILineaNegocioOptionsProps {
    url: string;
}

export interface ILineaNegocioOptionsState {
    items: Array<ILineaNegocioOptionsItem>;
}


export class LineaNegocioOptions extends Component<ILineaNegocioOptionsProps, ILineaNegocioOptionsState> {
    static displayName = LineaNegocioOptions.name;

    constructor(props: any) {
        super(props);

        this.state = {
            items: [],
        };
    }

    componentDidMount() {
        this.getData(this.props.url);
    }

    getData(url: string) { 
        debugger;
        var Query = "/api/LineasNegocio";
        var urlServicio = SERVICE_URL + Query;
        axios.get(urlServicio).then(res => {
            var data = res.data;
            //console.log(data);
            this.setState({ items: data })

            switch (url) {
                case "consumo-masivo": {
                    $("#consumo-masivo").addClass("container__linea__negocio_active");
                    break;
                }
                case "food-service": {
                    $("#food-service").addClass("container__linea__negocio_active");
                    break;
                }
                case "ventas-industriales": {
                    $("#ventas-industriales").addClass("container__linea__negocio_active");
                    break;
                }
                case "nutricion-vegetal": {
                    $("#nutricion-vegetal").addClass("container__linea__negocio_active");
                    break;
                }
                default: {
                    //statements; 
                    break;
                }
            } 
        })        
    }

    render() {
        var settings = {
            dots: true,
            infinite: false,
            speed: 500,
            slidesToShow: 4,
            slidesToScroll: 4,
            autoplay: true,
            autoplaySpeed: 10000,
            nextArrow: <SampleNextArrow />,
            prevArrow: <SamplePrevArrow />,
            responsive: [
                {
                    breakpoint: 1900,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 4,
                    }
                },
                {
                    breakpoint: 1300,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 4,
                    }
                },
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                    }
                },
                {
                    breakpoint: 640,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    }
                }
            ]
        };
        return (
            <div>
                <div className="container py-5">
                    <Carrousel {...settings}>
                        {this.state.items.map(function (d) {
                            return (
                                <div>
                                    <Link to={{ pathname: '/linea-negocio/' + d.urlSlug }} target="_blank" >
                                        <div id={d.urlSlug} className="container__linea__negocio">
                                            {d.titulo}
                                        </div>
                                    </Link>
                                </div>
                            )
                        })}
                    </Carrousel>                    
                </div>
            </div>
        );
    }
}
