import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { SERVICE_URL, SERVICE_IMG } from '../constants/global';

export class HistoriaHome extends Component {
    static displayName = HistoriaHome.name;

    constructor(props) {
        super(props);

        this.state = {
            items: [],
        };
    }

    componentDidMount() {
        this.getData();
    }

    getData() {
        var Query = "/api/Historia";
        var urlServicio = SERVICE_URL + Query;
        axios.get(urlServicio).then(res => {
            var data = res.data;
            //console.log(data);
            this.setState({ items: data })
        })
    }

    render() {
        return (
            <div className="history__container">
                <div className="container py-5">
                    <div className="text-center mb-4">
                        <h5 className="history__title">Historias</h5>
                        <p className="history__description">Descubra cómo el Grupo Ajinomoto resalta el sabor de los alimentos, mejora la salud y mejora la calidad de vida a través de historias nacidas de nuestra pasión por la vida.</p>
                    </div>
                    <div className="row row-cols-1 row-cols-md-3 g-4">
                        {this.state.items.map(function (d) {
                            var urlImagen = "";
                            if (d.rutaMiniatura != null && d.rutaMiniatura != undefined && d.rutaMiniatura != "") {
                                urlImagen = d.rutaMiniatura;
                            }
                            else {
                                urlImagen = d.rutaImagen;
                            }
                            return (
                                <div key={d.id} className="col mb-5">
                                    <div>
                                        <Link to={{ pathname: '/historia/' + d.urlSlug }} ><div className="img__header__content" style={{ backgroundImage: 'url(' + SERVICE_IMG + urlImagen + ')' }}></div></Link>
                                        <div className="mt-3">
                                            <Link to={{ pathname: '/historia/' + d.urlSlug }} ><h5 className="card-title history__card__title">{d.titulo}</h5></Link>                                            
                                            <p className="card-text history__card__description">{d.resumen}</p>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    <div className="text-center mb-4">
                        <Link to="/historias">
                            <button type="button" className="btn history__buton__vermas">Ver más</button>
                        </Link>   
                    </div>
                </div>
            </div>
        );
    }
}