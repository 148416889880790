import React, { Component } from 'react';
import axios from 'axios';
import { SERVICE_URL, SERVICE_IMG } from '../../constants/global';
import { Link } from 'react-router-dom';
import './Historia.css';
import * as moment from 'moment';
moment.locale("es");

export class HistoriaNosotros extends Component {
    static displayName = HistoriaNosotros.name;

    constructor(props) {
        super(props);

        this.state = {
            items: [],
        };
    }

    componentDidMount() {
        this.getData();
    }

    getData() {
        var Query = "/api/NosotrosHistoria";
        var urlServicio = SERVICE_URL + Query;
        axios.get(urlServicio).then(res => {
            var data = res.data;
            //console.log(data);
            this.setState({ items: data })
        })
    }

    render() {
        return (
            <div className="container container__pages py-5">
                <div className="container text-center mb-4">
                    <h2 className="fw-light">Historia</h2>
                </div>
                <section className="timeline">
                    <ul>
                        {this.state.items.map(function (d) {
                            return (
                                <li key={d.id}>
                                    <div>
                                        <time>{d.titulo}</time> {d.descripcion}
                                    </div>
                                    <div className='img__history'>
                                        <img src={SERVICE_IMG + d.rutaArchivo} className="img-fluid" alt="Header" />
                                    </div>
                                </li>
                            )
                        })}
                    </ul>
                </section>
            </div>
        );
    }
}
