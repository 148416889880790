import React, { Component } from 'react';
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';

const mapStyles = {
    position: 'relative',
    width: '100%',
    height: '330px',
};

export class MapContainer extends Component {
    render() {
        return (
            <Map
                google={this.props.google}
                zoom={12}
                style={mapStyles}
                className={'mapConsultas'}
                initialCenter={{
                    lat: - 12.08722,
                    lng: -77.02423
                }}
            >
                <Marker title={ "Ajinomoto del Perú" } position={{
                    lat: - 12.08722,
                    lng: -77.02423
                }} />
            </Map>
        );
    }    
}

export default GoogleApiWrapper({
    apiKey: 'AIzaSyD-YXAYg4AmuN9MChAhv1Qk4vvsnvXofyA'
})(MapContainer);
