import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { SERVICE_URL, SERVICE_IMG } from '../constants/global';

export class RedSocial extends Component {
    static displayName = RedSocial.name;

    constructor(props) {
        super(props);

        this.state = {
            items: [],
        };
    }

    componentDidMount() {
        this.getData();
    }

    getData() {
        var Query = "/api/RedSocial";
        var urlServicio = SERVICE_URL + Query;
        axios.get(urlServicio).then(res => {
            var data = res.data;
            //console.log(data);
            this.setState({ items: data })
        })
    }

    render() {
        return (
            <ul className="list-unstyled footer__sns">
                {this.state.items.map(function (d) {
                    return (
                         <li key={d.id}>
                            <a className="py-1 mr-2 d-block" href={d.url} target="_blank" rel="noopener"><img className="footer__sns-image" src={SERVICE_IMG + d.rutaArchivo} width="32" height="32" alt={d.titulo} loading="lazy" /></a>
                         </li>
                    )
                })}
            </ul>
        );
    }
}