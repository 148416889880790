import React, { Component } from 'react';
import axios from 'axios';
import { SERVICE_URL, SERVICE_IMG } from '../constants/global';
import { Link } from 'react-router-dom';
import * as moment from 'moment';
moment.locale("es");

export class Noticias extends Component {
    static displayName = Noticias.name;

    constructor(props) {
        super(props);

        this.state = {
            items: [],
        };
    }

    componentDidMount() {
        this.getData();
    }

    getData() {
        var Query = "/api/Noticia";
        var urlServicio = SERVICE_URL + Query;
        axios.get(urlServicio).then(res => {
            var data = res.data;
            //console.log(data);
            this.setState({ items: data })
        })
    }

    render() {
        return (
            <div>
                <header className="masthead">
                    <img src="../images/cabecera-noticias.jpg" className="img-fluid" alt="Header" />
                    <div className="carousel-caption">
                        <h1 className="display-2">Noticias</h1>
                        <h3>Mantenemos varios canales de comunicación para ayudar a las personas a aprender más sobre las actividades de Grupo Ajinomoto. Visite las secciones a continuación o contáctenos directamente.</h3>
                    </div>
                </header>
                <div className="container py-5">
                    <div className="container text-center mb-4">
                        <h2 className="fw-light">Noticias</h2>
                    </div>
                    <div className="row row-cols-1 row-cols-md-3 g-4">
                        {this.state.items.map(function (d) {
                            var urlImagen = "";
                            if (d.rutaMiniatura != null && d.rutaMiniatura != undefined && d.rutaMiniatura != "") {
                                urlImagen = d.rutaMiniatura;
                            }
                            else {
                                urlImagen = d.rutaArchivo;
                            }
                            return (
                                <div key={d.id} className="col mb-5">
                                    <div>
                                        <Link to={{ pathname: '/noticia/' + d.urlSlug }} ><div className="img__header__content" style={{ backgroundImage: 'url(' + SERVICE_IMG + urlImagen + ')' }}></div></Link> 
                                        <div className="mt-3">
                                            <Link to={{ pathname: '/noticia/' + d.urlSlug }} ><h5 className="card-title titleopcion">{d.titulo}</h5></Link>   
                                            <p className="card-text date_option">{moment(d.fechaPublicacion).format('ll')}</p>
                                            <p className="card-text description_option">{d.resumen}</p>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        );
    }
}
