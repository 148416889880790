import React from 'react';
import axios from 'axios';
import { SERVICE_URL, SERVICE_IMG } from '../constants/global';
import * as $ from 'jquery';
import './Receta.css';
import RecetasList from '../components/RecetasList';

export class Recetas extends React.Component {
    state = {
        recetas: [],
        page: 6,
        isLoading: false,
        isLoadingSearch: false,
        errorMsg: ''
    };

    componentDidMount() {
        this.loadRecetas();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.page !== this.state.page) {
            this.loadRecetas();
        }
    }

    loadRecetas = async () => {
        try {
            const { page } = this.state;
            var Query = `/api/Receta/GetAllRandom?top=${page}`;
            var urlServicio = SERVICE_URL + Query;

            this.setState({ isLoading: true });
            const response = await axios.get(
                urlServicio
            );

            this.setState((prevState) => ({
                recetas: [...prevState.recetas, ...response.data],
                errorMsg: ''
            }));
        } catch (error) {
            this.setState({
                errorMsg: 'Error al cargar los datos. Por favor intente luego.'
            });
        } finally {
            this.setState({ isLoading: false });
        }
    };

    loadMore = () => {
        this.setState((prevState) => ({
            page: prevState.page + 3
        }));
    };

    searchRecetas = () => {
        debugger;
        var Query = "/api/Receta/FiltroPrincipal";
        this.setState({
            recetas: [],
            isLoading: true
        });
        var urlServicio = SERVICE_URL + Query;
        var categoria = $("#searchTipo").val();
        var dificultad = $("#searchNivel").val();
        var recetaIngrediente = $("#searchReceta").val();

        if (categoria == "") {
            categoria = null;
        }

        if (dificultad == "") {
            dificultad = null;
        }

        if (recetaIngrediente == "") {
            recetaIngrediente = null;
        }

        const consulta = { categorias: categoria, dificultad: dificultad, recetaIngrediente: recetaIngrediente };

        const headers = {
            'content-type': 'application/json',
            "Access-Control-Allow-Methods": "OPTIONS,POST,GET"
        };


        axios.post(urlServicio, consulta, { headers })
            .then(response => {
                //console.log(response.data);
                this.setState({
                    recetas: response.data,
                    isLoading: false
                })
            })
            .catch(error => {
                console.log('There was an error!', error);
            });
    }

    render() {
        const { recetas, isLoading, errorMsg } = this.state;

        return (
            <div>
                <header className="masthead">
                    <img src="../images/bg_nutricion.jpg" className="img-fluid" alt="Header" />
                </header>
                <div className="container">
                    <section className="py-4">
                        <div className="container text-center mb-4">
                            <h2 className="fw-light receta__title">Recetas</h2>
                        </div>
                        <div className="bd-content p-5 mb-4 bg__receta__filter">
                            <h4 className="fw-light receta__title">¿Qué desea cocinar hoy?</h4>
                            <p className="receta__filter__description mb-4">Busca tu receta por categoría y nivel de dificultad o introduce un ingrediente.</p>
                            <div className="row">
                                <div className="col-lg-3">
                                    <select id="searchTipo" className="form-control receta__filter__select">
                                        <option selected={true} value="">Tipo de receta</option>
                                        <option value="Platos de Fondo">Platos de Fondo</option>
                                        <option value="Bajo calorías">Bajo calorías</option>
                                        <option value="Económicas">Económicas</option>
                                        <option value="Eco-recetas">Eco-recetas</option>
                                        <option value="Novedosas">Novedosas</option>
                                        <option value="Ocasiones especiales">Ocasiones especiales</option>
                                        <option value="Rápidas">Rápidas</option>
                                        <option value="Verduras">Verduras</option>
                                        <option value="Bajo en sodio">Bajo en sodio</option>
                                    </select>
                                </div>
                                <div className="col-lg-3">
                                    <select id="searchNivel" className="form-control receta__filter__select">
                                        <option selected={true} value="">Nivel de dificultad</option>
                                        <option value="Fácil">Fácil</option>
                                        <option value="Media">Media</option>
                                        <option value="Difícil">Difícil</option>
                                    </select>
                                </div>
                                <div className="col-lg-4">
                                    <input id="searchReceta" className="form-control receta__filter__select" placeholder="Escriba la receta o ingrediente" />
                                </div>
                                <div className="col-lg-2">
                                    <button id="btnBuscar" type="button" className="btn btn-danger receta__filter__search" onClick={this.searchRecetas}>Buscar</button>
                                </div>
                            </div>
                        </div>
                    </section>
                    <RecetasList recetas={recetas} />
                    {errorMsg && <p className="errorMsg">{errorMsg}</p>}
                    <div className="text-center mb-5">
                        <button onClick={this.loadMore} className="btn history__buton__vermas">
                            {isLoading ? 'Cargando...': 'Ver más'}
                        </button>
                    </div>
                </div>               
            </div>
        );
    }
}