import React from "react";
import { Link } from 'react-router-dom';
import { SERVICE_URL, SERVICE_IMG } from '../constants/global';

const Receta = ({ urlSlug, rutaArchivo, titulo, descripcionBreve }) => {
    return (
        <div key={urlSlug} className="col mb-5">
            <div>
                <Link to={{ pathname: '/receta/' + urlSlug }} target="_blank" >
                    <div className="img__header__content" style={{ backgroundImage: 'url(' + SERVICE_IMG + encodeURI(rutaArchivo) + ')' }}></div>
                </Link>
                <div className="mt-3">
                    <Link to={{ pathname: '/receta/' + urlSlug }} >
                        <h5 className="card-title titleopcion">{titulo}</h5>
                    </Link>
                    <p className="card-text description_option">{descripcionBreve}</p>
                </div>
            </div>
        </div>
    );
};

export default Receta;
