import React, { Component, useEffect, useState } from "react";
import Error from './error/Error';
import { Route } from 'react-router';
import { Layout } from './pages/Layout';
import { Home } from './pages/Home';
import { LineasNegocio } from './pages/LineasNegocio';
import { Nosotros } from './pages/Nosotros';
import { Umami } from './pages/Umami';
import { Noticias } from './pages/Noticias';
import { NoticiaDetail } from './components/NoticiaDetail';
import { UmamiDetail } from './components/UmamiDetail';
import { Nutricion } from './pages/Nutricion';
import { NutricionBienestar } from './pages/NutricionBienestar';
import { NutricionBienestarDetail } from './components/NutricionBienestarDetail';
import { Recetas } from './pages/Recetas';
import { RecetaDetail } from './components/RecetaDetail';
import { HistoriaDetail } from './components/HistoriaDetail';
import { OportunidadLaboral } from './pages/OportunidadLaboral';
import { Consultas } from './pages/Consultas';
import { DondeEstamos } from './pages/DondeEstamos';
import { Videos } from './pages/Videos';
import { Historia } from './pages/Historia';
import { LineaNegocioDetail } from './components/LineaNegocioDetail';
import { NoticiasNavigation } from './pages/NoticiasNavigation';
import { Producto } from './pages/Producto';
import { Reportes } from './pages/Reportes';
import { Buscador } from './pages/Buscador';
import { Boletin } from './pages/Boletin';
import { BoletinDetail } from './components/BoletinDetail';

import { AjinomotoPeru } from './pages/nosotros/AjinomotoPeru';
import { AjinomotoGroup } from './pages/nosotros/AjinomotoGroup';
import { PoliticaCalidad } from './pages/nosotros/PoliticaCalidad';
import { HistoriaNosotros } from './pages/nosotros/Historia';
import { NosotrosDetail } from './components/NosotrosDetail';

import { Tienda } from './components/Tienda';
import { PaginaDetail } from './components/Paginas';

import { TermsUse } from './pages/TermsUse';
import { PrivacyPolicy } from './pages/PrivacyPolicy';
import { SiteMap } from './pages/SiteMap';

import { Sostenibilidad } from './pages/Sostenibilidad';
import { MedioAmbiente } from './pages/MedioAmbiente';
import { ReporteSostenibilidad } from './pages/ReporteSostenibilidad';

import ReactGA from 'react-ga';

import './custom.css'

const App = () => {
    const TRACKING_ID = "G-YFKVSQB81F"; // OUR_TRACKING_ID
    const TRACKING_ID_MKT = "AW-16584436885"; // OUR_TRACKING_ID
    ReactGA.initialize(TRACKING_ID);
    ReactGA.initialize(TRACKING_ID_MKT);

    useEffect(() => {
        ReactGA.initialize(TRACKING_ID);
        ReactGA.initialize(TRACKING_ID_MKT);
    });

    return (
        <Layout>
            <Route exact path='/' component={Home} />
            <Route path='/sobre-nosotros' component={Nosotros} />
            <Route path='/lineas-negocio' component={LineasNegocio} />
            <Route path='/umami' component={Umami} />
            <Route path="/actividades" component={NoticiasNavigation} />
            <Route path='/noticias' component={Noticias} />
            <Route path='/nutricion' component={Nutricion} />
            <Route path='/nutricion-bienestar' component={NutricionBienestar} />
            <Route path='/donde-estamos' component={DondeEstamos} />
            <Route path='/oportunidad-laboral' component={OportunidadLaboral} />
            <Route path='/consultas' component={Consultas} />
            <Route path='/recetas' component={Recetas} />
            <Route path='/videos' component={Videos} />
            <Route path='/reportes' component={Reportes} />
            <Route path='/historias' component={Historia} />
            <Route path='/buscador' component={Buscador} />
            <Route path='/pagina/:url' component={PaginaDetail} />

            <Route path='/producto/:url' component={Producto} />
            <Route path="/noticia/:url" component={NoticiaDetail} />
            <Route path="/historia/:url" component={HistoriaDetail} />
            <Route path="/categoria/:url" component={UmamiDetail} />
            <Route path="/receta/:url" component={RecetaDetail} />
            <Route path="/linea-negocio/:url" component={LineaNegocioDetail} />
            <Route path="/nutricion-bienestar-det/:url" component={NutricionBienestarDetail} />

            <Route path="/nosotros/:url" component={NosotrosDetail} />
            <Route path='/ajinomoto-peru' component={AjinomotoPeru} />
            <Route path='/ajinomoto-group' component={AjinomotoGroup} />
            <Route path='/politica-calidad' component={PoliticaCalidad} />
            <Route path='/nuestra-historia' component={HistoriaNosotros} />

            <Route path='/boletines' component={Boletin} />
            <Route path="/boletin/:url" component={BoletinDetail} />

            <Route path='/tiendas' component={Tienda} />

            <Route path='/terms_of_use' component={TermsUse} />
            <Route path='/privacy_policy' component={PrivacyPolicy} />
            <Route path='/sitemap' component={SiteMap} />

            <Route path='/sostenibilidad' component={Sostenibilidad} />
            <Route path='/sostenibilidad-det/medio-ambiente' component={MedioAmbiente} />
            <Route path='/reporte-sostenibilidad' component={ReporteSostenibilidad} />
        </Layout>
    );
}

export default App;