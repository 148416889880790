import React, { Component } from 'react';
import axios from 'axios';
import { SERVICE_URL, SERVICE_IMG } from '../constants/global';
import { Link } from 'react-router-dom';
import * as moment from 'moment';
moment.locale("es");

export class Boletin extends Component {
    static displayName = Boletin.name;

    constructor(props) {
        super(props);

        this.state = {
            items: [],
        };
    }

    componentDidMount() {
        this.getData();
    }

    getData() {
        var Query = "/api/Boletin";
        var urlServicio = SERVICE_URL + Query;
        axios.get(urlServicio).then(res => {
            var data = res.data;
            //console.log(data);
            this.setState({ items: data })
        })
    }

    render() {
        return (
            <div>
                <header className="masthead">
                    <img src="../images/cabecera-noticias.jpg" className="img-fluid" alt="Header" />
                </header>
                <div className="container py-5">
                    <div className="container text-center mb-4">
                        <h2 className="fw-light">Boletines</h2>
                    </div>
                    <div className="row row-cols-1 row-cols-md-3 g-4">
                        {this.state.items.map(function (d) {
                            return (
                                <div key={d.id} className="col mb-5">
                                    <div>
                                        <Link to={{ pathname: '/boletin/' + d.urlSlug }} ><div className="img__header__content" style={{ backgroundImage: 'url(' + SERVICE_IMG + d.rutaArchivo + ')' }}></div></Link>
                                        <div className="mt-3">
                                            <Link to={{ pathname: '/boletin/' + d.urlSlug }} ><h5 className="card-title titleopcion">{d.titulo}</h5></Link>
                                            <p className="card-text description_option">{d.titulo}</p>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        );
    }
}
