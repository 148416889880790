import React, { Component } from 'react';
import ReactHtmlParser from 'react-html-parser';

export class PrivacyPolicy extends Component {
    static displayName = PrivacyPolicy.name;

    constructor(props) {
        super(props);
    }

    render() {
        var htmlBody = '<p class="mb-4">Ajinomoto del Perú S.A. ("Ajinomoto" o "nosotros") se esfuerza por garantizar el tratamiento adecuado de su información personal, sujeto a la siguiente política de privacidad:</p>';
        htmlBody += '<ol>' +
            '<li class="mb-3">Ajinomoto cumple con la Ley de Protección de Información Personal y otras leyes y regulaciones aplicables en el manejo de su información personal.</li>' +
            '<li class="mb-3">Cuando Ajinomoto recopila información personal, haremos pública una declaración de propósito que explica por qué se recopilará información personal o le notificaremos de tal propósito. En caso de que cambie el propósito del uso de su información personal que nos proporcionó, lo haremos público o le notificaremos el propósito cambiado.</li>' +
            '<li class="mb-3">Sin su consentimiento previo, Ajinomoto no utilizará su información personal más allá del alcance necesario para lograr el propósito de uso establecido en el párrafo inmediatamente anterior.</li>' +
            '<li class="mb-3">Ajinomoto ya toma las medidas adecuadas para garantizar la gestión segura de su información personal para proteger su información personal del acceso no autorizado, la divulgación, la pérdida y el daño.</li>' +
            '<li class="mb-3">Ajinomoto no proporcionará a terceros su información personal sin su consentimiento previo, excepto en los casos en que deleguemos el tratamiento de información personal a terceros en la medida necesaria para el uso o proporcionemos información personal legalmente a un tercero de conformidad con las leyes, regulaciones o reglas.</li>' +
            '<li class="mb-3">Ajinomoto responde adecuadamente a su solicitud de revelar, corregir, agregar o suspender el uso de los datos personales que nos proporciona. Para obtener más información sobre esta solicitud, comuníquese con la dirección o el sitio web a través del cual proporcionó dicha información personal.</li>' +
            '<li class="mb-3">Ajinomoto se esfuerza por desarrollar y mejorar el sistema interno diseñado para garantizar el tratamiento adecuado de la información personal y para llevar a cabo programas continuos de capacitación del personal con ese fin.</li>' +
            '</ol>';
        return (
            <div className="container container__pages py-5">
                <h2 className='text-center mb-4'>Política de Privacidad</h2>
                <div>
                    {ReactHtmlParser(htmlBody)}
                </div>
            </div>
        );
    }
}