import React, { Component } from 'react';
import axios from 'axios';
import { SERVICE_URL, SERVICE_IMG } from '../constants/global';
import { UmamiCategoria } from './../components/UmamiCategoria';

export interface IUmami {
    id: string;
    titulo: string;
}

export interface IUmamiProps {
}

export interface IUmamiState {
    items: Array<IUmami>;
}

export class Umami extends Component<IUmamiProps, IUmamiState> {
    static displayName = Umami.name;

    constructor(props: IUmamiProps) {
        super(props);

        this.state = {
            items: [],
        };
    }

    componentDidMount() {
        this.getDataCategoria();
    }

    getDataCategoria() {
        var Query = "/api/Umami/Categoria";
        var urlServicio = SERVICE_URL + Query;
        axios.get(urlServicio).then(res => {
            var data = res.data;
            //console.log(data);
            this.setState({ items: data })
        })
    }

    

    render() {
        return (
            <div>
                <header className="masthead">
                    <img src="../images/cabecera-umami.jpg" className="img-fluid" alt="Header" />
                    <div className="carousel-caption">
                        <h1 className="display-2">Umami</h1>
                        <h3>Umami, el poderoso quinto gusto, es importante para equilibrar los sabores en muchos de los alimentos que comemos. Es la delicia carnosa y sabrosa lo que profundiza el sabor y la razón por la que el caldo caliente, las carnes chamuscadas y los quesos curados son tan satisfactorios.</h3>
                    </div>
                </header>
                <div className="container py-5">
                    <div className="container mb-5">
                        {this.state.items.map(function (d) {
                            return (
                                <><h2 className="fw-light text-center mb-5">{d.titulo}</h2><UmamiCategoria idCategoria={d.id} /></>
                            )
                        })}
                        
                    </div>
                </div>
            </div>
        );
    }
}
