import React, { Component } from 'react';
import './SubSection.css';

export class DondeEstamos extends Component {
    static displayName = DondeEstamos.name;


  render () {
    return (
      <div>
            <header className="masthead">
                <img src="../images/cabecera-donde-estamos.jpg" className="img-fluid" alt="Header" />
                <div className="carousel-caption">
                    <h1 className="display-2">Donde estamos</h1>
                    <h3>Para consultas sobre el Grupo Ajinomoto, las empresas de nuestro grupo, actividades corporativas, cobertura de prensa, o para contactarnos directamente.</h3>
                </div>                
            </header>
            <div className="container py-5">
                <div className="row row-cols-1 row-cols-md-3 g-4">
                    <div className="col-lg-8">
                        <p className="title__section">NUESTRAS OFICINAS EN TODO EL PER&#218;</p>
                        <div className="section__bg">
                            <div className="mb-5">
                                <p className="subtitle__section">NORTE</p>
                                <div className="row row-cols-1 row-cols-md-2 g-4">
                                    <div className="col mb-3">
                                        <p className="subtitle__subtitle__section">Agencia Piura</p>
                                        <p className="description__subtitle__section">MZA 245 LOTE A ZONA INDUSTRIAL PIURA - PIURA - PIURA Tel&#233;fono: 968540585 Contacto: Luis Palomino</p>
                                    </div>
                                    <div className="col mb-3">
                                        <p className="subtitle__subtitle__section">Agencia Chiclayo</p>
                                        <p className="description__subtitle__section">Calle Manuel Seoane # 2080 La Victoria  Tel&#233;fono: 949674430 Contacto: Gabriel Ch&#225;vez</p>
                                    </div>
                                    <div className="col mb-3">
                                        <p className="subtitle__subtitle__section">Agencia Trujillo</p>
                                        <p className="description__subtitle__section">AV. FEDERICO VILLAREAL NRO. 258 URB. DANIEL HOYLE LA LIBERTAD - TRUJILLO - TRUJILLO Tel&#233;fono: 979682179 Contacto: Renzo Oberto-Besso</p>
                                    </div>
                                    <div className="col mb-3">
                                        <p className="subtitle__subtitle__section">Tambo Ja&#233;n</p>
                                        <p className="description__subtitle__section">Calle Diego Ferre Nro. 255 Jsec. Fila Alta Cajamarca - Jaen Tel&#233;fono: 971655765 Contacto: Jackson Perales</p>
                                    </div>
                                    <div className="col mb-3">
                                        <p className="subtitle__subtitle__section">Tambo Cajamarca</p>
                                        <p className="description__subtitle__section">AV. SAN MARTIN DE PORRAS NRO. 877 CAJAMARCA - CAJAMARCA - CAJAMARCA Tel&#233;fono: 971655765 Contacto: Jackson Perales</p>
                                    </div>                                   
                                    <div className="col mb-3">
                                        <p className="subtitle__subtitle__section">Tambo Tumbes</p>
                                        <p className="description__subtitle__section">OTR.CARRETERA PANAMERICANA NORTE KM. 1256 OTR. KILOMETRO NRO 1256 TUMBES - TUMBES - TUMBES Tel&#233;fono: 968540585 Contacto: Luis Palomino</p>
                                    </div>
                                    <div className="col mb-3">
                                        <p className="subtitle__subtitle__section">Tambo Chimbote</p>
                                        <p className="description__subtitle__section">AV. INDUSTRIAL NRO. 580 SEC. EL ACERO ANCASH - SANTA - CHIMBOTE Tel&#233;fono: 920322099 Contacto: Ivan Cuzquen</p>
                                    </div>
                                </div>
                            </div>
                            <div className="mb-5">
                                <p className="subtitle__section">ZONA SUR</p>
                                <div className="row row-cols-1 row-cols-md-2 g-4">
                                    <div className="col mb-3">
                                        <p className="subtitle__subtitle__section">Agencia Arequipa</p>
                                        <p className="description__subtitle__section">AV. PIZARRO NRO. 113 AREQUIPA - AREQUIPA - JOSE LUIS BUSTAMANTE Y RIVERO Tel&#233;fono: 945466194 Contacto: &#193;ngel Riveros</p>
                                    </div>
                                    <div className="col mb-3">
                                        <p className="subtitle__subtitle__section">Tambo Tacna</p>
                                        <p className="description__subtitle__section">PQ. PARQUE INDUSTRIAL NRO. MZ C INT. LT 9 Z.I. PARQUE INDUSTRIAL TACNA Tel&#233;fono: 999965528 Contacto: John Botello</p>
                                    </div>
                                    <div className="col mb-3">
                                        <p className="subtitle__subtitle__section">Tambo Pedregal</p>
                                        <p className="description__subtitle__section">POBLADO EL PEDREGAL MZA. A31 LOTE. 02 (POBLADO EL PEDREGAL LOTE URBANO NRO-2) AREQUIPA - CAYLLOMA - MAJES Tel&#233;fono: 945466194 Contacto: &#193;ngel Riveros</p>
                                    </div>
                                </div>
                            </div>       
                            <div className="mb-5">
                                <p className="subtitle__section">CENTRO SUR</p>
                                <div className="row row-cols-1 row-cols-md-2 g-4">
                                    <div className="col mb-3">
                                        <p className="subtitle__subtitle__section">Tambo Cusco, Tambo Juliaca / Tambo Huancayo, Tambo La Merced / Tambo Norte Chico, Tambo Hu&#225;nuco / Tambo Ica, Tanbo Chincha / Tambo Ayacucho</p>
                                        <p className="description__subtitle__section">(01) 470-6050 Anexos:3242, 3515, 3244 y 3245 Contacto: Staff de Ventas</p>
                                    </div>
                                </div>
                            </div> 
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <p className="title__section">REGI&#211;N ANDINA</p>
                        <div className="section__bg">
                            <div className="mb-5">
                                <p className="subtitle__section">ECUADOR</p>
                                <div className="row row-cols-1 row-cols-md-1 g-4">
                                    <div className="col mb-3">
                                        <p className="subtitle__subtitle__section">Sazonadores del Pac&#237;fico Ltda.</p>
                                        <p className="description__subtitle__section">Carretera Guayaquil - Daule km.11 &#189; Av. Principal Parque Industrial "El Sauce" Guayaquil - Ecuador. Tel&#233;fono: (593) (4) 2103461 Contacto: ecuador@lim.ajinomoto.com</p>
                                    </div>
                                </div>
                            </div>
                            <div className="mb-5">
                                <p className="subtitle__section">BOLIVIA</p>
                                <div className="row row-cols-1 row-cols-md-1 g-4">
                                    <div className="col mb-3">
                                        <p className="subtitle__subtitle__section">Ajinomoto del Per&#250; S.A. (Sucursal Bolivia)</p>
                                        <p className="description__subtitle__section">Calle V&#237;ctor Pinto, Edificio: Torre D&#250;o Piso 13 Nro. 4200 Barrio Equipetrol Santa Cruz de la Sierra - Bolivia. Tel&#233;fono: (591) 33446468 Contacto: bolivia@lim.ajinomoto.com</p>
                                    </div>
                                </div>
                            </div>
                            <div className="mb-5">
                                <p className="subtitle__section">CHILE</p>
                                <div className="row row-cols-1 row-cols-md-1 g-4">
                                    <div className="col mb-3">
                                        <p className="subtitle__subtitle__section">Ajinomoto del Per&#250; S.A.(Sucursal Chile)</p>
                                        <p className="description__subtitle__section">Av. Los Militares 4611, Oficina 1006 Las Condes Santiago de Chile Tel&#233;fono: (00) (56) 233282615 Contacto: chile@lim.ajinomoto.com</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
      </div>
    );
  }
}
