import React, { Component } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { SERVICE_URL, SERVICE_IMG } from '../constants/global';
import { Helmet } from "react-helmet";
import Carrousel from "react-slick";
import "../../node_modules/slick-carousel/slick/slick.css";
import "../../node_modules/slick-carousel/slick/slick-theme.css";

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", zIndex: 99999 }}
            onClick={onClick}
        />
    );
}
function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", zIndex: 99999 }}
            onClick={onClick}
        />
    );
}

export class LineasNegocio extends Component {
    static displayName = LineasNegocio.name;

    constructor(props) {
        super(props);

        this.state = {
            items: [],
        };
    }

    componentDidMount() {
        this.getData();
    }

    getData() {
        var Query = "/api/LineasNegocio/";
        var urlServicio = SERVICE_URL + Query;
        axios.get(urlServicio).then(res => {
            var data = res.data;
            //console.log(data);
            this.setState({ items: data })
        })
    }

    render() {
        var settings = {
            dots: true,
            infinite: false,
            speed: 500,
            slidesToShow: 4,
            slidesToScroll: 4,
            autoplay: true,
            autoplaySpeed: 10000,
            nextArrow: <SampleNextArrow />,
            prevArrow: <SamplePrevArrow />,
            responsive: [
                {
                    breakpoint: 1900,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 4,
                    }
                },
                {
                    breakpoint: 1300,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 4,
                    }
                },
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                    }
                },
                {
                    breakpoint: 640,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    }
                }
            ]
        };
        return (
            <div>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>L&#237; neas de Negocio | Ajinomoto del Per�</title>
                    <meta property="og:title" content="L�neas de Negocio | Ajinomoto del Per�" />
                    <meta property="og:image" content="https://www.ajinomoto.com.pe/images/bg_lineasnegocio.jpg" />
                </Helmet>
                <header className="masthead">
                    <img src="../images/bg_lineasnegocio.jpg" className="img-fluid" alt="Header" />
                    <div className="carousel-caption">
                        <h1 className="display-2">L&#237;neas de negocio</h1>
                        <h3>Contamos con productos seleccionados de alta calidad para todo nuestro p&#250;blico peruano.</h3>
                    </div>
                </header>
                <div className="container py-5">
                    <Carrousel {...settings}>
                        {this.state.items.map(function (d) {
                            return (
                                <div>
                                    <Link to={{ pathname: '/linea-negocio/' + d.urlSlug }} >
                                        <div className="container__linea__negocio">
                                            {d.titulo}
                                        </div>
                                    </Link>                                   
                                </div>
                            )
                        })}
                    </Carrousel>
                    <div className="row row-cols-1 row-cols-md-3 g-4 mt-4">
                        {this.state.items.map(function (d) {
                            var urlImagen = "";
                            if (d.rutaMiniatura != null && d.rutaMiniatura != undefined && d.rutaMiniatura != "") {
                                urlImagen = d.rutaMiniatura;
                            }
                            else {
                                urlImagen = d.rutaArchivo;
                            }
                            return (
                                <div key={d.id} className="col mb-5">
                                    <div>
                                        <Link to={{ pathname: '/linea-negocio/' + d.urlSlug }} ><div className="img__header__content" style={{ backgroundImage: 'url(' + SERVICE_IMG + urlImagen + ')' }}></div></Link>                                        
                                        <div className="mt-3">
                                            <Link to={{ pathname: '/linea-negocio/' + d.urlSlug }} ><h5 className="card-title titleopcion">{d.titulo}</h5></Link>
                                            <p className="card-text description_option">{d.descripcion}</p>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        );
    }
}
