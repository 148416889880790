import React, { Component } from 'react';
import axios from 'axios';
import { SERVICE_URL, SERVICE_IMG } from '../constants/global';
import ReactHtmlParser from 'react-html-parser';
import { Helmet } from "react-helmet";
import moment from 'moment';
moment.locale("es");

export interface IHistoriaDetailProps {
    url: string;
}

export interface IHistoriaDetailState {
    id: number,
    titulo: string,
    resumen: string,
    descripcionBreve: string,
    rutaArchivo: string,
    activo: boolean,
    rutaMiniatura: string,
}

export class HistoriaDetail extends Component<any, IHistoriaDetailState> {
    private params: any;
    static displayName = HistoriaDetail.name;

    constructor(props: any) {
        super(props);

        this.state = {
            id: 0,
            titulo: '',
            resumen: '',
            descripcionBreve: '',
            rutaArchivo: '',
            activo: true,
            rutaMiniatura: ''
        }
    }

    componentDidMount() {
        this.getData(this.props.match.params.url);
    }

    getData(url: string) {
        var Query = "/api/Historia/ByUrl/" + url;
        var urlServicio = SERVICE_URL + Query;
        axios.get(urlServicio).then(res => {
            var data = res.data;
            //console.log(data);
            this.setState({
                id: data.id,
                titulo: data.titulo,
                resumen: data.resumen,
                descripcionBreve: data.descripcionBreve,
                rutaArchivo: data.rutaArchivo,
                activo: true,
                rutaMiniatura: data.rutaMiniatura,
            })
        })
    }

    render() {
        var urlImagen = "";
        if (this.state.rutaArchivo != null && this.state.rutaArchivo != undefined && this.state.rutaArchivo != "") {
            urlImagen = this.state.rutaArchivo;
        }
        else {
            urlImagen = this.state.rutaMiniatura;
        }

        return (
            <div>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>{this.state.titulo} | Ajinomoto del Perú</title>
                    <meta property="og:title" content={this.state.titulo + ' | Ajinomoto del Perú'} />
                    <meta property="og:image" content={SERVICE_IMG + urlImagen} />
                </Helmet>

                <div className="container container__pages py-5">
                    <img src={SERVICE_IMG + this.state.rutaArchivo} className="img-fluid" alt="Header" />
                    <div className="mb-4 mt-4">
                        <h5 className="card-title titleopcion">{this.state.titulo}</h5>
                        <div>
                            {ReactHtmlParser(this.state.descripcionBreve)}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
