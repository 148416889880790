import React, { Component } from 'react';
import axios from 'axios';
import { SERVICE_URL, SERVICE_IMG } from '../constants/global';
import { Link } from 'react-router-dom';

export class NutricionBienestar extends Component {
    static displayName = NutricionBienestar.name;

    constructor(props) {
        super(props);

        this.state = {
            items: [],
        };
    }

    componentDidMount() {
        this.getData();
    }

    getData() {
        var Query = "/api/NutricionBienestar";
        var urlServicio = SERVICE_URL + Query;
        axios.get(urlServicio).then(res => {
            var data = res.data;
            //console.log(data);
            this.setState({ items: data })
        })
    }

    render() {
        return (
            <div>
                <header className="masthead">
                    <img src="../images/bg_nutricion.jpg" className="img-fluid" alt="Header" />
                    <div className="carousel-caption">
                        <h1 className="display-2">Nutrición</h1>
                        <h3>Liberar el poder de los aminoácidos para apoyar la vida sana de personas en todo el mundo.</h3>
                    </div>
                </header>
                <div className="container py-5">
                    <div className="row row-cols-1 row-cols-md-3 g-4">
                        {this.state.items.map(function (d) {
                            var urlImagen = "";
                            if (d.rutaMiniatura != null && d.rutaMiniatura != undefined && d.rutaMiniatura != "") {
                                urlImagen = d.rutaMiniatura;
                            }
                            else {
                                urlImagen = d.rutaArchivo;
                            }
                            return (
                                <div key={d.id} className="col mb-5">
                                    <div>
                                        <Link to={{ pathname: '/nutricion-bienestar-det/' + d.urlSlug }} ><div className="img__header__content" style={{ backgroundImage: 'url(' + SERVICE_IMG + urlImagen + ')' }}></div></Link>
                                        <div className="mt-3">
                                            <Link to={{ pathname: '/nutricion-bienestar-det/' + d.urlSlug }} ><h5 className="card-title titleopcion">{d.titulo}</h5></Link>
                                            <p className="card-text description_option">{d.descripcion}</p>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        );
    }
}