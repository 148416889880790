/* eslint-disable array-callback-return */
/* eslint-disable react/jsx-no-target-blank */
import React, { Component } from 'react';
import axios from 'axios';
import { SERVICE_URL, SERVICE_IMG } from '../constants/global';
import ReactHtmlParser from 'react-html-parser';
import { Helmet } from "react-helmet";
import './RecetaDetail.css';
import { IProductoItem } from '../models/IProductoItem';
import { IAporteNutricionalItem } from '../models/IAporteNutricionalItem';
import { IOctogonoItem } from '../models/IOctogonoItem';
import RecetasList from '../components/RecetasList';
import OctogonoList from '../components/OctogonoList';
import { Link } from 'react-router-dom';
import moment from 'moment';
moment.locale("es");

export interface IRecetaDetailProps {
    url: string
}

export interface IRecetaDetailState {
    id: number,
    titulo: string,
    descripcionBreve: string,
    dificultad: string,
    ingredientes: string,
    preparacion: string,
    porciones: number,
    duracion: string,
    categorias: string,
    productos: Array<IProductoItem>,
    urlSlug: string,
    rutaArchivo: string,
    rutaMiniatura: string,
    aporteNutricional: Array<IAporteNutricionalItem>,
    octogonos: Array<IOctogonoItem>,
    recetas: [],
}

export class RecetaDetail extends Component<any, IRecetaDetailState> {
    private params: any;
    static displayName = RecetaDetail.name;

    constructor(props: any) {
        super(props);

        this.state = {
            id: 0,
            titulo: '',
            descripcionBreve: '',
            dificultad: '',
            ingredientes: '',
            preparacion: '',
            porciones: 0,
            duracion: '',
            categorias: '',
            productos: [],
            urlSlug: '',
            rutaArchivo: '',
            rutaMiniatura: '',
            aporteNutricional: [],
            octogonos: [],
            recetas: [],
        }

        setTimeout(() => {
            this.getBarProgress()
        }, 1000);
    }

    componentDidMount() {
        this.getData(this.props.match.params.url);
        this.loadRecetas();
    }

    getBarProgress() {
        //Construye Array
        var elementos = document.querySelectorAll('div.percent');
        var valores: any[] = [];
        elementos.forEach(elemento => {
            if (elemento) {
                var valor = elemento.getAttribute('valor');
                valores.push(valor);
            }
        });

        console.log(valores);


        //Aplica cada valor del Array a su respectiva barra de Progreso
        var progressBars = document.getElementsByClassName(
            'progress-bar',
        ) as HTMLCollectionOf<HTMLElement>;

        if (progressBars.length >= valores.length) {
            for (let i = 0; i < valores.length; i++) {
                progressBars[i].style.width = valores[i] + '%';
            }
        } else {
            console.log('No hay suficientes elementos "progress-bar" para asignar valores.');
        }
    }

    getData(url: string) {
        var Query = "/api/Receta/ByUrlAndDetails/" + url;
        var urlServicio = SERVICE_URL + Query;
        axios.get(urlServicio).then(res => {
            var data = res.data;
            console.log(data);
            this.setState({
                id: data.id,
                titulo: data.titulo,
                descripcionBreve: data.descripcionBreve,
                dificultad: data.dificultad,
                ingredientes: data.ingredientes,
                preparacion: data.preparacion,
                porciones: data.porciones,
                duracion: data.duracion,
                categorias: data.categorias,
                productos: data.productos,
                urlSlug: data.urlSlug,
                rutaArchivo: data.rutaArchivo,
                rutaMiniatura: data.rutaMiniatura,
                aporteNutricional: data.aporteNutricional,
                octogonos: data.octogonos,
            })
        })
    }

    loadRecetas() {
        var Query = `/api/Receta/GetAllRandom?top=${3}`;
        var urlServicio = SERVICE_URL + Query;
        axios.get(urlServicio).then(res => {
            var data = res.data;
            console.log(data);
            this.setState({
                recetas: data,
            })
        })
    }

    render() {

        var urlImagen = "";
        if (this.state.rutaArchivo != null && this.state.rutaArchivo !== undefined && this.state.rutaArchivo !== "") {
            urlImagen = this.state.rutaArchivo;
        }
        else {
            urlImagen = this.state.rutaMiniatura;
        }

        var urlFacebook = "http://www.facebook.com/sharer.php?u=" + window.location.href + "&t=" + this.state.titulo;
        var urlTwitter = "https://twitter.com/intent/tweet?url=" + window.location.href + "&text=" + this.state.titulo;
        var urlLinkedin = "https://www.linkedin.com/shareArticle?mini=true&url=" + window.location.href;
        var urlWhatsApp = "https://api.whatsapp.com/send?text=" + window.location.href;

        var categorias = this.state.categorias;
        var newcategorias = "";

        let divStyleleCategoria = {
            display: 'inherit',
        };        

        if (categorias.includes(";")) {
            var cat = categorias.split(";");

            cat.forEach(function (value) {
                if (value !== "") {
                    newcategorias += value + ",";
                }
            });
        }

        if (categorias === "") {
            divStyleleCategoria = {
                display: 'none',
            };
        }        

        var _newcategorias = newcategorias.substring(0, newcategorias.length - 1);

        if (this.state.aporteNutricional.length > 0) {
            return (
                <div>
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>{this.state.titulo} | Ajinomoto del Perú</title>
                        <meta property="og:title" content={this.state.titulo + ' | Ajinomoto del Perú'} />
                        <meta property="og:image" content={SERVICE_IMG + urlImagen} />
                    </Helmet>
                    <div className="container py-5">
                        <h5 className="card-title title__receta mb-4">{this.state.titulo}</h5>
                        <div className="row">
                            <div className="col-lg-7 mb-2">
                                <img alt={this.state.titulo} src={SERVICE_IMG + this.state.rutaArchivo} className="img-fluid imagen__receta" />
                            </div>
                            <div className="col-lg-5 container__aporte_nutricional">
                                <div className="caja_aporte_nutricional">
                                    <div className="titulo">Aporte nutricional</div>
                                    {this.state.aporteNutricional.map((d) => {
                                        const valorprops = { valor: d.porcentaje };
                                        return (
                                            <div key={d.id} className={"item"}>
                                                <div className="name">
                                                    {d.tituloAporteNutricional}
                                                </div>
                                                <div className="gramos">
                                                    {d.cantidad}
                                                </div>
                                                <div className="barra">
                                                    <div className="progress-bar" style={{ width: d.porcentaje + "%" }} ></div>
                                                    <div className="percent" {...valorprops} >{d.porcentaje}%</div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="receta_caja_detalle">
                                    <div className="c">
                                        <div className="box" style={divStyleleCategoria}>
                                            Categorías:
                                            <span> {_newcategorias}</span>
                                        </div>
                                        <div className="box">
                                            Preparación:
                                            <span> {this.state.duracion}</span>
                                        </div>
                                        <div className="box">
                                            Porciones:
                                            <span> {this.state.porciones}</span>
                                        </div>
                                        <div className="box">
                                            Dificultad:
                                            <span> {this.state.dificultad}</span>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="caja_preparacion_ingredientes">
                            <OctogonoList ingredientes={this.state.ingredientes} preparacion={this.state.preparacion }
                                octogonos={this.state.octogonos} />
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="container__share">
                                    <ul className="list-unstyled footer__share">
                                        <li className="label__share">Compartir en: </li>
                                        <li><a className="py-1 mr-2 d-block" href={urlFacebook} target="_blank" rel="noopener"><img className="footer__sns-image" src="../images/ico-fb-receta.svg" width="32" height="32" alt="Facebook" loading="lazy" /></a></li>
                                        <li><a className="py-1 mr-2 d-block" href={urlTwitter} target="_blank" rel="noopener"><img className="footer__sns-image" src="../images/ico-tw-receta.svg" width="32" height="32" alt="Twitter" loading="lazy" /></a></li>
                                        <li><a className="py-1 mr-2 d-block" href={urlLinkedin} target="_blank" rel="noopener"><img className="footer__sns-image" src="../images/ico-in-receta.svg" width="32" height="32" alt="Linkedin" loading="lazy" /></a></li>
                                        <li><a className="py-1 mr-2 d-block" href={urlWhatsApp} target="_blank" rel="noopener"><img className="footer__sns-image" src="../images/ico-wa-receta.svg" width="32" height="32" alt="Linkedin" loading="lazy" /></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <hr className="divisor__recetas__bottom" />
                        <h5 className="card-title title__receta mb-4 mt-5 text-left">Productos para esta receta</h5>
                        <div className="row row-cols-1 row-cols-md-3 g-4">
                            {this.state.productos.map((d) => {

                                if (d.tiendas.length > 0) {
                                    return (
                                        <div key={d.id} className={"col mb-5"}>
                                            <div>
                                                <Link to={{ pathname: '/producto/' + d.urlSlug }} ><div className="img__header__content" style={{ backgroundImage: 'url(' + SERVICE_IMG + d.rutaMiniatura + ')' }}></div></Link>
                                                <div className="mt-4 row">
                                                    <div className="col-lg-10">
                                                        <Link to={{ pathname: '/producto/' + d.urlSlug }} ><h5 className="card-title history__card__title">{d.titulo}</h5></Link>
                                                    </div>
                                                    <div className="col-lg-2 carrito__container">
                                                        <img src="../images/ico_carrito.svg" alt={d.titulo} className="carrito__img" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                                else {
                                    return (
                                        <div key={d.id} className={"col mb-5"}>
                                            <div>
                                                <Link to={{ pathname: '/producto/' + d.urlSlug }} ><div className="img__header__content" style={{ backgroundImage: 'url(' + SERVICE_IMG + d.rutaMiniatura + ')' }}></div></Link>
                                                <div className="mt-4 row">
                                                    <div className="col-lg-12">
                                                        <Link to={{ pathname: '/producto/' + d.urlSlug }} ><h5 className="text-center card-title history__card__title">{d.titulo}</h5></Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }

                            })}
                        </div>

                        <hr className="divisor__recetas__bottom" />
                        <h5 className="card-title title__receta mb-4 mt-5 text-left">Más recetas</h5>
                        <RecetasList recetas={this.state.recetas} />
                    </div>

                </div>
            );
        }

        else {
            return (
                <div>
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>{this.state.titulo} | Ajinomoto del Perú</title>
                        <meta property="og:title" content={this.state.titulo + ' | Ajinomoto del Perú'} />
                        <meta property="og:image" content={SERVICE_IMG + urlImagen} />
                    </Helmet>
                    <div className="container py-5">
                        <h5 className="card-title title__receta mb-4">{this.state.titulo}</h5>
                        <div className="row">
                            <div className="col-lg-12 mb-2 text-center">
                                <img alt={this.state.titulo} src={SERVICE_IMG + this.state.rutaArchivo} className="img-fluid imagen__receta" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="receta_caja_detalle">
                                    <div className="c">
                                        <div className="box" style={divStyleleCategoria}>
                                            Categorías:
                                            <span> {_newcategorias}</span>
                                        </div>
                                        <div className="box">
                                            Preparación:
                                            <span> {this.state.duracion}</span>
                                        </div>
                                        <div className="box">
                                            Porciones:
                                            <span> {this.state.porciones}</span>
                                        </div>
                                        <div className="box">
                                            Dificultad:
                                            <span> {this.state.dificultad}</span>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="caja_preparacion_ingredientes">
                            <OctogonoList ingredientes={this.state.ingredientes} preparacion={this.state.preparacion}
                                octogonos={this.state.octogonos} />
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="container__share">
                                    <ul className="list-unstyled footer__share">
                                        <li className="label__share">Compartir en: </li>
                                        <li><a className="py-1 mr-2 d-block" href={urlFacebook} target="_blank" rel="noopener"><img className="footer__sns-image" src="../images/ico-fb-receta.svg" width="32" height="32" alt="Facebook" loading="lazy" /></a></li>
                                        <li><a className="py-1 mr-2 d-block" href={urlTwitter} target="_blank" rel="noopener"><img className="footer__sns-image" src="../images/ico-tw-receta.svg" width="32" height="32" alt="Twitter" loading="lazy" /></a></li>
                                        <li><a className="py-1 mr-2 d-block" href={urlLinkedin} target="_blank" rel="noopener"><img className="footer__sns-image" src="../images/ico-in-receta.svg" width="32" height="32" alt="Linkedin" loading="lazy" /></a></li>
                                        <li><a className="py-1 mr-2 d-block" href={urlWhatsApp} target="_blank" rel="noopener"><img className="footer__sns-image" src="../images/ico-wa-receta.svg" width="32" height="32" alt="Linkedin" loading="lazy" /></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <hr className="divisor__recetas__bottom" />
                        <h5 className="card-title title__receta mb-4 mt-5 text-left">Productos para esta receta</h5>
                        <div className="row row-cols-1 row-cols-md-3 g-4">
                            {this.state.productos.map((d) => {

                                if (d.tiendas.length > 0) {
                                    return (
                                        <div key={d.id} className={"col mb-5"}>
                                            <div>
                                                <Link to={{ pathname: '/producto/' + d.urlSlug }} ><div className="img__header__content" style={{ backgroundImage: 'url(' + SERVICE_IMG + d.rutaMiniatura + ')' }}></div></Link>
                                                <div className="mt-4 row">
                                                    <div className="col-lg-10">
                                                        <Link to={{ pathname: '/producto/' + d.urlSlug }} ><h5 className="card-title history__card__title">{d.titulo}</h5></Link>
                                                    </div>
                                                    <div className="col-lg-2 carrito__container">
                                                        <img src="../images/ico_carrito.svg" alt={d.titulo} className="carrito__img" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                                else {
                                    return (
                                        <div key={d.id} className={"col mb-5"}>
                                            <div>
                                                <Link to={{ pathname: '/producto/' + d.urlSlug }} ><div className="img__header__content" style={{ backgroundImage: 'url(' + SERVICE_IMG + d.rutaMiniatura + ')' }}></div></Link>
                                                <div className="mt-4 row">
                                                    <div className="col-lg-12">
                                                        <Link to={{ pathname: '/producto/' + d.urlSlug }} ><h5 className="text-center card-title history__card__title">{d.titulo}</h5></Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }

                            })}
                        </div>

                        <hr className="divisor__recetas__bottom" />
                        <h5 className="card-title title__receta mb-4 mt-5 text-left">Más recetas</h5>
                        <RecetasList recetas={this.state.recetas} />
                    </div>

                </div>
            );
        }


    }
}
