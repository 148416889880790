import React, { Component } from 'react';
import axios from 'axios';
import { SERVICE_URL, SERVICE_IMG } from '../constants/global';
import ReactHtmlParser from 'react-html-parser';
import moment from 'moment';
import { Helmet } from "react-helmet";
moment.locale("es");

export interface INosotrosDetailProps {
    url: string;
}

export interface INosotrosDetailState {
    id: number,
    titulo: string,
    resumen: string,
    contenido: string,
    rutaImagen: string,
    rutaMiniatura: string,
    activo: boolean
}

export class NosotrosDetail extends Component<any, INosotrosDetailState> {
    private params: any;
    static displayName = NosotrosDetail.name;

    constructor(props: any) {
        super(props);

        this.state = {
            id: 0,
            titulo: '',
            resumen: '',
            contenido: '',
            rutaImagen: '',
            rutaMiniatura: '',
            activo: true
        }
    }

    componentDidMount() {
        this.getData(this.props.match.params.url);
    }

    getData(url: string) {
        var Query = "/api/Nosotros/ByUrl/" + url;
        var urlServicio = SERVICE_URL + Query;
        axios.get(urlServicio).then(res => {
            var data = res.data;
            //console.log(data);
            this.setState({
                id: data.id,
                titulo: data.titulo,
                resumen: data.resumen,
                contenido: data.contenido,
                rutaImagen: data.rutaImagen,
                rutaMiniatura: data.rutaMiniatura,
                activo: true
            })
        })
    }

    render() {
        var urlImagen = "";
        if (this.state.rutaImagen != null && this.state.rutaImagen != undefined && this.state.rutaImagen != "") {
            urlImagen = this.state.rutaImagen;
        }
        else {
            urlImagen = this.state.rutaMiniatura;
        }

        return (
            <div>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>{this.state.titulo} | Ajinomoto del Perú</title>
                    <meta property="og:title" content={this.state.titulo + ' | Ajinomoto del Perú'} />
                    <meta property="og:image" content={SERVICE_IMG + urlImagen} />
                </Helmet>
                <div className="container container__pages py-5">
                    <h2 className="mb-4 text-center">{this.state.titulo}</h2>
                    <img src={SERVICE_IMG + this.state.rutaImagen} className="img-fluid" alt="Header" />
                    <div className="mb-4 mt-4">                        
                        {ReactHtmlParser(this.state.contenido)}
                    </div>
                </div>
            </div>
        );
    }
}
