import React, { Component } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import 'bootstrap/dist/css/bootstrap.min.css';

import axios from 'axios';
import './PoliticaCalidad.css';
import { SERVICE_URL, SERVICE_IMG } from '../../constants/global';
import ReactHtmlParser from 'react-html-parser';

export class PoliticaCalidad extends Component {
    static displayName = PoliticaCalidad.name;

    constructor(props) {
        super(props);

        this.state = {
            id: 0,
            titulo: '',
            resumen: '',
            contenido: '',
            rutaImagen: '',
            items: [],
        };
    }

    componentDidMount() {
        this.getDataPolitica();
        this.getData();
    }

    getDataPolitica() {
        var Query = "/api/Nosotros/ByUrl/politica-calidad";
        var urlServicio = SERVICE_URL + Query;
        axios.get(urlServicio).then(res => {
            var data = res.data;
            //console.log(data);
            this.setState({
                id: data.id,
                titulo: data.titulo,
                resumen: data.resumen,
                contenido: data.contenido,
                rutaImagen: data.rutaImagen
            })
        })
    }

    getData() {
        var Query = "/api/NosotrosPolitica";
        var urlServicio = SERVICE_URL + Query;
        axios.get(urlServicio).then(res => {
            var data = res.data;
            //console.log(data);
            this.setState({ items: data })
        })
    }

    render() {
        return (
            <div>
                <div className="container container__pages py-5">
                    <h2 className="fw-light text-center">Política de calidad</h2>
                    <div className="mb-4 mt-4">
                        <img src={SERVICE_IMG + this.state.rutaImagen} className="img-fluid" alt="Header" />
                        <div className="mb-4 mt-4">
                            {ReactHtmlParser(this.state.contenido)}
                        </div>
                    </div>
                    <div className="mt-5">
                        <h3 className="fw-light text-left mb-3">Proceso de producción</h3>
                        <Accordion>
                        {this.state.items.map(function (d) {
                            return (
                                <Accordion.Item eventKey={d.id} className='mb-2'>
                                    <Accordion.Header>{d.titulo}</Accordion.Header>
                                    <Accordion.Body>
                                        <div className="text-center"><img src={SERVICE_IMG + d.rutaArchivo} className="img-fluid text-center mb" alt="politica" /></div>
                                        <div className="politica__descripcion p-4">
                                            {ReactHtmlParser(d.descripcion)}
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            )
                        })}
                        </Accordion>
                    </div>
                </div>
            </div>
        );
    }
}
