import React, { Component } from 'react';
import './SubSection.css';
import ReactHtmlParser from 'react-html-parser';

export class OportunidadLaboral extends Component {
    static displayName = OportunidadLaboral.name;

    constructor(props) {
        super(props);
    }

    render() {
        const html1 = `<div><p>En Ajinomoto del Per&#250; S.A., empresa l&#237;der en el sector de alimentos y sazonadores, estamos comprometidos con la buena alimentaci&#243;n, el bienestar de la sociedad y el medioambiente. Como parte del Grupo Ajinomoto, l&#237;der mundial en la investigaci&#243;n e innovaci&#243;n tecnol&#243;gica, compartimos el lema "Comer Bien, Vivir Bien". <br><br>Nuestro principal negocio es satisfacer las necesidades de la sociedad ofreciendo productos sabrosos y pr&#225;cticos, elaborados con los m&#225;s altos est&#225;ndares de calidad y, al mismo tiempo, compartimos con nuestra gente, valores fundamentales que sustentan las operaciones y actividades del Grupo Ajinomoto. </p></div>`;
        const html2 = '<div><p>Somos parte de ABE Asociaci&#243;n de Buenos Empleadores; valoramos la individualidad de todas las personas, nos esforzamos para que cada uno de los integrantes de esta gran Familia pueda desarrollarse y desplegar al m&#225;ximo sus habilidades; para que asuman permanentemente el reto de incursionar en nuevos negocios, nuevos mercados, sin miedo ni temor al fracaso, valorando el aprendizaje en cada proceso, que permanentemente creen nuevo valor a trav&#233;s de la <br></p></div>';
        const html3 = '<div><p>innovaci&#243;n, fruto de nuestra tecnolog&#237;a y la ciencia de los amino&#225;cidos; siempre considerando las necesidades de nuestra sociedad con el prop&#243;sito de aportar mayor valor a trav&#233;s de nuestras actividades comerciales.<br><br>Constantemente buscamos que nuestro Personal sienta orgulloso de pertenecer a esta gran empresa; S&#250;mate a este gran equipo; AS ONE TEAM.</p></div>';

        return (

            <div>
                <header className="masthead">
                    <img src="../images/cabecera-oportunidad-laboral.jpg" className="img-fluid" alt="Header" />
                    <div className="carousel-caption">
                        <h1 className="display-2">Oportunidades Laborales</h1>
                        <h3>La oportunidad de formar parte de una de las familias m&#225;s unidas y preocupadas por el desarrollo de cada uno de nuestros colaboradores.</h3>
                    </div>
                </header>
                <div className="container py-5">
                    <div className="row row-cols-1 row-cols-md-3 g-4">
                        <div class="col-lg-8">
                            <p className="title__section">&#191;POR QU&#201; TRABAJAR CON NOSOTROS&#63;</p>
                            <div className="section__bg">
                                <div className="row row-cols-1 row-cols-md-2 g-4 mb-2">
                                    <div className="col mb-2">
                                        <p className="description__subtitle__oportunidad">
                                            {ReactHtmlParser(html1)}
                                        </p>
                                    </div>
                                    <div className="col mb-2">
                                        <img src="../images/linkedin-ajonomoto.png" className="img-fluid" />
                                    </div>
                                    <div className="col mb-1">
                                        <p className="description__subtitle__oportunidad">
                                            {ReactHtmlParser(html2)}
                                        </p> 
                                    </div>
                                    <div className="col mb-1">
                                        <p className="description__subtitle__oportunidad">
                                            {ReactHtmlParser(html3)}
                                        </p>                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <p className="title__section">&nbsp;</p>
                            <div className="section__bg">
                                <div className="row row-cols-1 row-cols-md-1 g-4">
                                    <div className="col mb-2">
                                        <p className="description__subtitle__oportunidad">Gracias por su inter&#233;s en formar parte de nuestra empresa.
                                            <br /><br />
                                            Los procesos de reclutamiento y selecci&#243;n de Ajinomoto del Per&#250; S.A. se hacen de manera directa y sin intermediarios a trav&#233;s de nuestros canales oficiales en: Bumeran, Computrabajo, Laborum y Linkedin. Te recomendamos tener tu hoja de vida actualizada en cualquiera de estas plataformas.</p>
                                    </div>
                                    <div className="col">
                                        <div className="row mb-2 section__oportunidad">
                                            <a href="https://www.bumeran.com.pe/empleos-busqueda-ajinomoto.html" target="_blank" rel="noopener"><img src="../images/oportunidad-bumeran.png" className="img-fluid" /></a>
                                        </div>
                                        <div className="row mb-2 section__oportunidad">
                                            <a href="https://www.computrabajo.com.pe/empresas/ofertas-de-trabajo-de-ajinomoto-del-peru-sa-D30FC21620372FAA" target="_blank" rel="noopener"><img src="../images/oportunidad-computrabajo.png" className="img-fluid" /></a>
                                        </div>
                                        <div className="row mb-2 section__oportunidad">
                                            <a href="https://www.linkedin.com/company/35442910/admin/" target="_blank" rel="noopener"><img src="../images/oportunidad-linkedin.png" className="img-fluid" /></a>
                                        </div>                                        
                                        <div className="row section__oportunidad">
                                            <a href="https://laborum.pe/p/buscar-ofertas?inputquery=ajinomoto" target="_blank" rel="noopener"><img src="../images/oportunidad-laborum.png" className="img-fluid" /></a>
                                        </div>                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        );
    }
}
