import React, { Component } from 'react';
import axios from 'axios';
import * as $ from 'jquery';
import { SERVICE_URL, SERVICE_IMG } from '../constants/global';
import CircularProgress from '@material-ui/core/CircularProgress';
import BuscadorList from '../components/BuscadorList';

export interface IBuscadorProps {
    word: string;
}

export class Buscador extends Component<any, any> {
    static displayName = Buscador.name;

    constructor(props: any) {
        super(props);

        this.state = {
            resultados: [],
            isLoading: false,
            errorMsg: '',
            cantidad: ''
        }
    }

    componentDidMount() {
        const queryParameters = new URLSearchParams(window.location.search);
        const word = queryParameters.get("word");
        if (word != "" && word != null && word != undefined) {
            this.getData(word);
        }
        
    }

    getData = (word: string) => {
        $("#searchConsulta").val(word);
        this.setState({
            resultados: [],
            isLoading: true
        });

        var Query = "/api/Busqueda?search=" + word;
        var urlServicio = SERVICE_URL + Query;

        const headers = {
            'content-type': 'application/json',
            "Access-Control-Allow-Methods": "OPTIONS,POST,GET"
        };

        axios.post(urlServicio, { headers })
            .then(response => {
                //console.log(response.data);
                this.setState({
                    resultados: response.data,
                    isLoading: false,
                    cantidad: 'Resultados: ' + response.data.length
                })
            })
            .catch(error => {
                console.log('There was an error!', error);
                this.setState({
                    resultados: [],
                    isLoading: false,
                    cantidad: 'No se encontraron resultados'
                })
            });
    }

    search = () => {
        debugger;
        
        this.setState({
            resultados: [],
            isLoading: true
        });
        
        var searchConsulta = $("#searchConsulta").val();
        var Query = "/api/Busqueda?search=" + searchConsulta;
        var urlServicio = SERVICE_URL + Query;

        const headers = {
            'content-type': 'application/json',
            "Access-Control-Allow-Methods": "OPTIONS,POST,GET"
        };

        axios.post(urlServicio, { headers })
            .then(response => {
                //console.log(response.data);
                this.setState({
                    resultados: response.data,
                    isLoading: false,
                    cantidad: 'Resultados: ' + response.data.length 
                })
            })
            .catch(error => {
                console.log('There was an error!', error);
                this.setState({
                    resultados: [],
                    isLoading: false,
                    cantidad: 'No se encontraron resultados'
                })
            });
    }


    render() {
        const { resultados, isLoading, errorMsg, cantidad } = this.state;

        return (
          <div>
                <div className="container container__pages py-5">
                    <div className="container text-center mb-4">
                        <h3 className="fw-light">Buscador</h3>
                    </div>
                    <div className="bd-content p-5 mb-4 bg__receta__filter">
                        <div className="row">
                            <div className="col-lg-10">
                                <input id="searchConsulta" className="form-control receta__filter__select" placeholder="Escribir" />
                            </div>
                            <div className="col-lg-2">
                                <button id="btnBuscar" type="button" className="btn btn-dark" onClick={this.search}>Buscar</button>
                            </div>
                        </div>
                    </div>
                    <div className="text-center mb-5">
                        {isLoading && <CircularProgress color="primary" />}
                        {!isLoading &&
                            <>
                                <p className="search__cantidad">{cantidad}</p><br />
                                <BuscadorList resultados={resultados} />
                            </>
                        }
                    </div>
                </div>            
          </div>
        );
  }
}
