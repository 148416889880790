import React from 'react';
import { SERVICE_IMG } from '../constants/global';
import ReactHtmlParser from 'react-html-parser';

const OctogonoList = ({ ingredientes, preparacion, octogonos }) => {
    if (octogonos.length > 0) {
        return (
            <div className="row">
                <div className="col-lg-5 mb-2">
                    <h5 className="card-title subtitle__receta mb-4">Preparación</h5>
                    {ReactHtmlParser(preparacion)}
                </div>
                <div className="col-lg-5 mb-2">
                    <h5 className="card-title subtitle__receta mb-4">Ingredientes</h5>
                    {ReactHtmlParser(ingredientes)}
                </div>
                <div className="col-lg-2">
                    <div className="octagonos">
                        {octogonos && octogonos.map((oct) => <img alt={oct.tituloOctogono} src={SERVICE_IMG + oct.rutaArchivoOctogono} />)}
                    </div>
                    <div className="evitar">
                        <img alt="evitar" src="../images/evitar.png" />
                    </div>
                </div>
            </div>
        );
    }

    else {
        return (
            <div className="row">
                <div className="col-lg-6 mb-2">
                    <h5 className="card-title subtitle__receta mb-4">Preparación</h5>
                    {ReactHtmlParser(preparacion)}
                </div>
                <div className="col-lg-6 mb-2">
                    <h5 className="card-title subtitle__receta mb-4">Ingredientes</h5>
                    {ReactHtmlParser(ingredientes)}
                </div>
            </div>
        );
    }
    
};

export default OctogonoList;
