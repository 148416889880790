import React, { Component } from 'react';
import axios from 'axios';
import { SERVICE_URL, SERVICE_IMG } from '../constants/global';
import ReactHtmlParser from 'react-html-parser';
import moment from 'moment';
moment.locale("es");

export interface INutricionBienestarDetailProps {
    url: string;
}

export interface INutricionBienestarDetailState {
    id: number,
    titulo: string,
    descripcion: string,
    contenido: string,
    rutaArchivo: string,
    activo: boolean
}

export class NutricionBienestarDetail extends Component<any, INutricionBienestarDetailState> {
    private params: any;
    static displayName = NutricionBienestarDetail.name;

    constructor(props: any) {
        super(props);

        this.state = {
            id: 0,
            titulo: '',
            descripcion: '',
            contenido: '',
            rutaArchivo: '',
            activo: true
        }
    }

    componentDidMount() {
        this.getData(this.props.match.params.url);
    }

    getData(url: string) {
        var Query = "/api/NutricionBienestar/ByUrl/" + url;
        var urlServicio = SERVICE_URL + Query;
        axios.get(urlServicio).then(res => {
            var data = res.data;
            //console.log(data);
            this.setState({
                id: data.id,
                titulo: data.titulo,
                descripcion: data.descripcion,
                contenido: data.contenido,
                rutaArchivo: data.rutaArchivo
            })
        })
    }

    render() {
        return (
            <div>
                <div className="container container__pages py-5">
                    <img src={SERVICE_IMG + this.state.rutaArchivo} className="img-fluid" alt="Header" />
                    <div className="mb-4 mt-4">
                        <h5 className="card-title titleopcion">{this.state.titulo}</h5>
                        <div>
                            {ReactHtmlParser(this.state.contenido)}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
