import React, { Component } from 'react';

export class AjinomotoPeru extends Component {
    static displayName = AjinomotoPeru.name;


    render() {
        return (
            <div>

            </div>
        );
    }
}
