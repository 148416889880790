import React, { Component } from 'react';
import axios from 'axios';
import { SERVICE_URL, SERVICE_IMG } from '../constants/global';
import { Link } from 'react-router-dom';

export class MedioAmbiente extends Component {
    static displayName = MedioAmbiente.name;

    constructor(props) {
        super(props);

        this.state = {
            items: [],
        };
    }

    render() {
        return (
            <div>
                <header className="masthead">
                    <img src="../images/foto-cabecera-medioambiente.jpg" className="img-fluid" alt="Header" />
                    <div className="carousel-caption">
                        <h1 className="display-2">Medio Ambiente</h1>
                        <h3>Tenemos la convicción que una gestión sostenible es fundamental para garantizar la preservación de los recursos naturales. Es por ello que planificamos e implementamos actividades que contribuyen al cuidado del planeta.</h3>
                    </div>
                </header>
                <div className="container py-5">
                    <div className="row row-cols-1 row-cols-md-3 g-4">
                        <div class="col mb-5">
                            <div>
                                <a href="/reporte-sostenibilidad">
                                    <div class="img__header__content" style={{
                                        backgroundImage: 'url(/images/MINIATURA-reporte.jpg)'
                                    }}></div>
                                </a>
                                <div class="mt-3">
                                    <a href="/reporte-sostenibilidad">
                                        <h5 class="card-title titleopcion">Reportes de sostenibilidad</h5>
                                    </a>
                                    <p class="card-text description_option">En esta sección presentamos nuestro desempeño en sostenibilidad a nivel local y global.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}