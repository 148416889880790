import React, { Component } from 'react';
import './Sostenibilidad.css';

export class ReporteSostenibilidad extends Component {
    static displayName = ReporteSostenibilidad.name;

    constructor(props) {
        super(props);

        this.state = {
            items: [],
        };
    }

    render() {
        return (
            <div>                
                <div className="container sostenibilidad-reporte container__pages py-5">
                    <h2 className="mb-4 text-center">Reporte de Sostenibilidad</h2>
                    <img src="/images/foto-sostenibilidad.jpg" className="img-fluid" alt="Header" />
                    <div className="mb-4 mt-4">
                        <div className="bloque-gris">
                            <img src="/images/Portada-Reporte-MA-Ajinomoto-2023.jpg" alt="buenas prácticas" />
                            <div className="texto">
                                <h2 className="title_report">Primer reporte de sostenibilidad ambiental 2023</h2>
                                <p>Desde hace más de 25 años, en Ajinomoto del Perú estamos comprometidos con operar
                                    cuidando el ambiente. Hoy queremos presentarles este compromiso en nuestro primer
                                    Informe de Buenas Prácticas Ambientales 2023, donde podrán conocer nuestro desempeño
                                    ambiental y sus resultados.</p>
                                <a href="/files/Reporte Sostenibilidad-MA-Ajinomoto-2023.pdf" target="_blank" className="btn">Descargar PDF <img src="/images/ic-pdf-blanco.png" alt="Descargar" /></a>
                            </div>
                        </div>

                        <div className="bloque-rojo mb-5">
                            <img src="/images/reporte-2023.png" alt="reporte 2023" />
                            <div className="texto">
                                    <h2 className="title_report">Reporte integrado ASV 2023 del Grupo Ajinomoto</h2>
                                    <p>Esta publicación corporativa global se centra en el concepto de “AminoScience” que es
                                        nuestra base en la creación de valor compartido del Grupo Ajinomoto (ASV). Contiene
                                        nuestro avance en la Hoja de Ruta de la corporación al 2030 desde una óptica de
                                    sostenibilidad y gobierno corporativo.</p>
                                <a style={{ width:'120px' }} href="https://www.ajinomoto.co.jp/company/en/ir/library/annual.html" rel="noreferrer" target="_blank" className="btn btn-outline-primary">Ver enlace</a>
                                </div>
                        </div>
                        <div style={{ display: 'none' }}>
                            <h2 className="title_report">Historial de reportes</h2>
                            <ul class="reporte-descarga">
                                <li><a href="/">Reporte de sostenibilidad
                                    2022</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
