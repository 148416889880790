import React, { Component, useEffect, useState } from "react";
import axios from 'axios';
import { SERVICE_URL, SERVICE_IMG } from '../constants/global';
import { Form } from "react-bootstrap";

export const ConsultaProductoRuta1 = (peIdTipoNegocio) => {
    const [vlListValores, setListValores] = useState([]);
    var Query = "/api/Producto/ByLineaNegocio/" + peIdTipoNegocio.peIdTipoNegocio;
    var urlServicio = SERVICE_URL + Query;

    useEffect(() => {
        axios
            .get(urlServicio)
            .then((response) => {
                if (response.status === 200) {
                    setListValores(response?.data);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    });

    return (
        <div className="form-group">
            <Form.Select defaultValue={""} className="form-control consulta__input" placeholder="Selecciona una opción" id="contact__producto__ruta1" required>
                <option value=""> Selecciona un producto</option>
                {vlListValores.map(function (item, key) {
                    return (
                        <option key={key} value={item.id}>{item.titulo}</option>
                    );
                })}
            </Form.Select>
        </div>
    );
}