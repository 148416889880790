import React from "react";
import { Link } from 'react-router-dom';
import { SERVICE_URL, SERVICE_IMG } from '../constants/global';
import ReactHtmlParser from 'react-html-parser';

const BuscadorResultado = ({ titulo, descripcion, tipoContenido, url, rutaMiniatura }) => {
    var decripcion__result = "";
    if (descripcion.length > 390) {
        decripcion__result = descripcion.substring(0, 390);
    }
    else {
        decripcion__result = descripcion;
    }

    let tipoContenido__style = {
        display : 'inherit'
    }

    var tipoContenido__result = "";
    if (tipoContenido != "" && tipoContenido != null && tipoContenido != undefined) {
        switch (tipoContenido) {
            case "Nosotros": {
                tipoContenido__result = "/nosotros/" + url; 
                break;
            }
            case "Umami": {
                tipoContenido__result = "/categoria/" + url;
                break;
            }
            case "Producto": {
                tipoContenido__result = "/producto/" + url;
                break;
            }
            case "Nutricion": {
                tipoContenido__result = "/nutricion-bienestar/" + url;
                break;
            }
            case "Receta": {
                tipoContenido__result = "/receta/" + url;
                break;
            }
            case "Noticia": {
                tipoContenido__result = "/noticia/" + url;
                break;
            }
            case "Historias": {
                tipoContenido__result = "/historias/" + url;
                break;
            }
            default: {
                tipoContenido__result = url;
                break;
            }
        } 
    }
    else {
        tipoContenido__style = {
            display: 'none'
        }
    }

    if (rutaMiniatura != "" && rutaMiniatura != null && rutaMiniatura != undefined) {
        return (
            <div key={titulo} className="mb-3">
                <div className="row">
                    <div className="col-lg-3 mt-3 text-right">
                        <img className="search___imagen" src={SERVICE_IMG + rutaMiniatura} />
                    </div>
                    <div className="col-lg-9 mt-3 text-left">
                        <p className="card-text search___title">{titulo}</p>
                        <p className="card-text search___description"> {ReactHtmlParser(decripcion__result)}</p>
                        <Link to={{ pathname: tipoContenido__result }} style={tipoContenido__style} className="search___link" >Ver más</Link>
                    </div>
                </div>
            </div>
        );
    }
    else {
        return (
            <div key={titulo} className="mb-3">
                <div className="row">
                    <div className="col-lg-12 mt-3 text-left">
                        <p className="card-text search___title">{titulo}</p>
                        <p className="card-text search___description"> {ReactHtmlParser(decripcion__result)}</p>
                        <Link to={{ pathname: tipoContenido__result }} style={tipoContenido__style}  className="search___link" >Ver más</Link>
                    </div>
                </div>
            </div>
        );
    }

    
};

export default BuscadorResultado;
