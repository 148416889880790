/* eslint-disable react/jsx-no-target-blank */
import React, { Component } from 'react';
import './Footer.css';
import { RedSocial } from './../components/RedSocial';

export class Footer extends Component {
    static displayName = Footer.name;

    render() {
        return (
            <footer className="footer">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-lg-12 mt-5">
                            <div className="row">
                                <div className="col-md-3 mb-md-0 mb-0">
                                    <h2 className="footer-heading"><a href="/sobre-nosotros">Sobre nosotros</a></h2>
                                    <ul className="list-unstyled">
                                        <li><a href="/nosotros/ajinomoto-peru" className="footer__submenu-link py-1 d-block">Ajinomoto del Perú</a></li>
                                        <li><a href="/nosotros/ajinomoto-group" className="footer__submenu-link py-1 d-block">Ajinomoto Group</a></li>
                                        <li><a href="/nuestra-historia" className="footer__submenu-link py-1 d-block">Historia</a></li>
                                        <li><a href="/nosotros/valor-compartido-ajinomoto" className="footer__submenu-link py-1 d-block">Valor compartido Ajinomoto (ASV)</a></li>
                                        <li><a href="/politica-calidad" className="footer__submenu-link py-1 d-block">Política de calidad</a></li>
                                    </ul>
                                </div>
                                <div className="col-md-3 mb-md-0 mb-0">
                                    <h2 className="footer-heading"><a href="/lineas-negocio">Líneas de negocio</a></h2>
                                    <ul className="list-unstyled">
                                        <li><a href="/linea-negocio/consumo-masivo" className="footer__submenu-link py-1 d-block">Consumo masivo</a></li>
                                        <li><a href="/linea-negocio/food-service" className="footer__submenu-link py-1 d-block">Food service</a></li>
                                        <li><a href="/linea-negocio/ventas-industriales" className="footer__submenu-link py-1 d-block">Ventas industriales</a></li>
                                        <li><a href="/linea-negocio/nutricion-vegetal" className="footer__submenu-link py-1 d-block">Nutrición vegetal</a></li>
                                    </ul>
                                    <h2 className="footer-heading"><a href="/recetas">Recetas</a></h2>
                                    <h2 className="footer-heading"><a href="/umami">Umami</a></h2>                                    
                                </div>
                                <div className="col-md-3 mb-md-0 mb-0">
                                    <h2 className="footer-heading"><a href="/nutricion">Nutrición</a></h2>
                                    <ul className="list-unstyled">
                                        <li><a href="/nutricion-bienestar" className="footer__submenu-link py-1 d-block">Nutrición y bienestar</a></li>
                                        <li><a href="/recetas" className="footer__submenu-link py-1 d-block">Recetas</a></li>
                                        <li><a href="/pagina/proyecto-menos-sal-y-mas-sabor" className="footer__submenu-link py-1 d-block">Proyecto Menos sal y Más sabor</a></li>
                                    </ul>
                                    <h2 className="footer-heading"><a href="/actividades">Noticias</a></h2>
                                    <h2 className="footer-heading"><a href="http://www.fedtt-peru.com/Mercurio_Ajinomoto_PROD_Portal/Paginas/home" target="_blank" rel="noopener">Retenciones electrónicas</a></h2>
                                    <h2 className="footer-heading"><a href="http://www.fedtt-peru.com/Mercurio_Ajinomoto_PROD_Portal/Paginas/home" target="_blank" rel="noopener">Facturación electrónica</a></h2>
                                </div>
                                <div className="col-md-3 mb-md-0 mb-0">
                                    <h2 className="footer-heading">Síganos en</h2>
                                    <RedSocial />
                                    <div className="footer-add-menu">
                                        <img src="../images/ico_ajipanda.svg" alt="Ajipanda" width="24" height="20" className="footer-add-menu__symbol" loading="lazy"/>
                                        <a target="_blank" rel="noopener" href="https://www.ajinomoto.com/aboutus/ajipanda-official-page" className="footer-add-menu__link">AjiPanda Official Page</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 col-lg-12 mt-5 mb-5">
                            <div className="footer__in">
                                <ul className="footer__navi">
                                    <li className="footer__navi-item"><a className="footer__navi-link" href="/sitemap">Mapa del sitio</a></li>
                                    <li className="footer__navi-item"><a className="footer__navi-link" href="/privacy_policy">Aviso de Privacidad</a></li>
                                    <li className="footer__navi-item"><a className="footer__navi-link" href="/terms_of_use">Términos de Uso</a></li>
                                    <li className="footer__navi-item"><a className="footer__navi-link" href="../files/Identificación de beneficiario final.pdf" target="_blank" rel="noopener">Declaración beneficiario final</a></li>
                                    <li className="footer__navi-item"><a className="footer__navi-link" href="/consultas">Contacto</a></li>
                                </ul>
                                <p className="gt-block footer__copy mt-5">
                                    <small className="footer__copy-text">
                                        {"©" + new Date().getFullYear() +  " Ajinomoto del Perú S.A."}
                                    </small>
                                </p>
                            </div>
                        </div>
                        
                    </div>                    
                </div>
			</footer>
        );
    }
}
